import { Box, Container, color } from "@mui/system";
import React, { Ref, forwardRef, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Switch,
  Input,
  Button,
  Select,
  Paper,
  MenuItem,
  InputAdornment,
  CircularProgress,
  IconButton,
  Slider,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Formik,
  FormikHelpers,
  FormikValues,
  FormikErrors,
} from "formik";
import * as Yup from "yup";
import { axiosRequestLocal } from "../../../api/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { addMonths } from "date-fns";
// import "./MyForm.css";
import "./MyForm.css";
import {
  MuiColorInput,
  MuiColorInputValue,
  MuiColorInputColors,
  MuiColorInputFormat,
} from "mui-color-input";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import type { ToastOptions } from "react-toastify";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Base_Url } from "../../../api/api";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import styled, { css } from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from 'uuid';
import { uniqueId } from "lodash";
import { useNavigate } from "react-router-dom";

const boxStyle = {
  color: "white",
};

const supportedImageTypes = ["image/jpeg", "image/png", "image/gif"];

interface FileWithTypeInfo extends File {
  type: string;
}

const isImageFile = (file: FileWithTypeInfo) =>
  supportedImageTypes.includes(file.type);
const xummWalletAddressRegex = /^r[a-z0-9]{24,34}$/i;

interface Collection {
  collectionName: string;
  taxonId: number;
  group: number;
}

interface Layer {
  id:number;
  title: string;
  layerNumber: string;
  traitImages: File[];
  require: number;
}
interface Burnables {
  name: string;
  taxonId: number;
}

interface FormDataInterface {
  name: string;
  campaignType: number;
  title: string;
  description: string;
  background: File | null;
  logo: File | null;
  createdBy: string;
  // url:string,
  fontSize: string;
  fontFamily: string;
  backgroundColor: string;
  buttonColor: string;
  launch_Date: Date;
  end_Date: Date | null;
  status: string;
  collectionAddresses: Collection[];

  layers: Layer[];
  burnables: Burnables[];
  schema: string;
  targetMintingDescription: string;
  collection_name: string;
  collection_family: string;
  base_name: string;
  taxon: number;
  nft_type: string;
  issuerId: string;
  transfer_fee_percentage_x_thousand: number;
}

const initialValues: FormDataInterface = {
  name: "",
  campaignType: 0,
  title: "",
  description: "",
  createdBy: "",
  // url:"",
  launch_Date: new Date(),
  background: null,
  fontSize: "",
  fontFamily: "",
  backgroundColor: "rgb(0, 0, 0)",
  buttonColor: "rgb(0, 0, 0)",
  logo: null,
  end_Date: null,
  status: "",
  collectionAddresses: [
    {
      collectionName: "",
      taxonId: 0,
      group: 0,
    },
  ],
  layers: [{ id:0,title: "", layerNumber: "", require: 1, traitImages: [] }],
  burnables: [{ name: "", taxonId: 0 }],
  schema: "",
  targetMintingDescription: "",
  collection_name: "",
  collection_family: "",
  base_name: "",
  taxon: 0,
  nft_type: "",
  issuerId: "",
  transfer_fee_percentage_x_thousand: 0,
};

const collectionAddressesSchema = Yup.array().of(
  Yup.object().shape({
    collectionName: Yup.string().required("Collection Name is required"),
    taxonId: Yup.number()
      .positive("Taxon should be a positive number")
      .required("Taxon Id is required"),
    group: Yup.number()
      .required("Group is required")
      .min(1, "Group must be selected"),
  })
);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    // .matches(/^[A-Za-z0-9\s]*$/, "Only alphabets and numbers are allowed")
    .required("Name is required"),
  campaignType: Yup.number()
    .required("Campaign Type is required")
    .min(1, "Campaign Type must be selected"),
  title: Yup.string()
    // .matches(/^[A-Za-z0-9\s]*$/, "Only alphabets and numbers are allowed")
    .required("Title is required"),
  description: Yup.string()
    .required("Descripiton is required")
    .max(120, "Description must be at most 200 characters"),
  background: Yup.mixed()
    .required("Background image is required")
    .test(
      "fileType",
      "Only image files are allowed",
      (value) =>
        value && value instanceof File && value.type.startsWith("image/")
    )
    .test(
      "fileSize",
      "File size should be less than or equal to 20MB",
      (value) =>
        value && value instanceof File && value.size <= 20 * 1024 * 1024
    ),

  fontSize: Yup.string().required("Font Size is required"),
  fontFamily: Yup.string().required("Font Family is required"),

  backgroundColor: Yup.string().required("Background Color is required"),
  buttonColor: Yup.string().required("Button Color is required"),
  logo: Yup.mixed()
    .required("Logo is required")
    .test(
      "fileType",
      "Only image files are allowed",
      (value) =>
        value && value instanceof File && value.type.startsWith("image/")
    )
    .test(
      "fileSize",
      "File size should be less than or equal to 20MB",
      (value) =>
        value && value instanceof File && value.size <= 20 * 1024 * 1024
    ),
  launch_Date: Yup.date().required("Launch Date is required"),
  end_Date: Yup.date().required("End date is required"),
  status: Yup.string().required("Status is required"),

  collectionAddresses: Yup.mixed().when(
    "campaignType",
    (campaignType: any, schema: any) => {
      return campaignType != 3 ? collectionAddressesSchema : schema;
    }
  ),

  layers: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .matches(/^[A-Za-z\s]*$/, "Only alphabets are allowed")
        .required("Title is required"),
      layerNumber: Yup.number()
        .required("Layer Number is required")
        .min(1, "Layer number must be greater than 0"),

      traitImages: Yup.array()
        .min(1, "Please select at least one file")
        .test(
          "fileType",
          "Only image files are allowed",
          (value) =>
            value &&
            Array.isArray(value) &&
            value.every(
              (file) => file instanceof File && file.type.startsWith("image/")
            )
        )
        .test(
          "fileSize",
          "File size should be less than or equal to 20MB",
          (value) =>
            value &&
            Array.isArray(value) &&
            value.every(
              (file) => file instanceof File && file.size <= 20 * 1024 * 1024
            )
        ),
    })
  ),
  burnables: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      taxonId: Yup.number()
        .positive("Taxon should be a positive number")
        .required("Taxon Id is required"),
    })
  ),
  // schema: Yup.string().url("Invalid URL").required("Schema is required"),
  schema: Yup.string().required("Schema is required"),
  targetMintingDescription: Yup.string().required("Description is required"),
  collection_name: Yup.string().required("Collection Name is required"),
  collection_family: Yup.string().required("Collection Family is required"),
  base_name: Yup.string()
    .matches(/^[A-Za-z0-9\s]*$/, "Only alphabets and numbers are allowed")
    .required("Base Name is required"),
  taxon: Yup.number()
    .min(1, "Taxon should be greater than 0")
    .required("Taxon is required"),
  nft_type: Yup.string().required("NFT Type is required"),
  transfer_fee_percentage_x_thousand: Yup.number()
  .moreThan(0, "Transfer Fee percentage thousand should be greater than 0")
  .required("Transfer Fee percentage thousand is required"),
  issuerId: Yup.string()
    .matches(/^r[0-9a-zA-Z]{33}$/, "Issuer is not in the correct format")
    .required("Issuer is required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface campaignPropI {
  setRefresh: Function;
}

const CampaignInfoComp = ({ setRefresh }: campaignPropI) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [targetcampaignType, setcampaignType] = useState("");
  const [update, setUpdate] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (
    values: FormDataInterface,
    actions: FormikHelpers<FormDataInterface>
  ) => {
    console.log(values);
    const formDatas = new FormData();
    formDatas.append("Campaign[name]", values?.name);
    formDatas.append("Campaign[campaignType]", values?.campaignType.toString());
    formDatas.append("Campaign[title]", values?.title);
    formDatas.append("Campaign[description]", values?.description);
    formDatas.append("Campaign[status]", values?.status);
    formDatas.append(
      "Campaign[launch_Date]",
      values?.launch_Date.toISOString()
    );
    if (values?.end_Date) {
      formDatas.append("Campaign[end_Date]", values.end_Date.toISOString());
    } else {
      formDatas.append("Campaign[end_Date]", ""); // Set an appropriate default value when end_Date is null
    }
    formDatas.append("Campaign[fontSize]", values?.fontSize);
    formDatas.append("Campaign[fontFamily]", values?.fontFamily);
    formDatas.append("Campaign[backgroundColor]", values?.backgroundColor);
    formDatas.append("Campaign[buttonColor]", values?.buttonColor);
    if (values?.background) {
      formDatas.append("Campaign[background]", values?.background);
    } else {
      formDatas.append("Campaign[background]", ""); // Empty string or appropriate default value
    }
    if (values?.logo) {
      formDatas.append("Campaign[logo]", values?.logo);
    } else {
      formDatas.append("Campaign[logo]", ""); // Empty string or appropriate default value
    }
    formDatas.append("Campaign[createdBy]", values?.createdBy);

    values.collectionAddresses.forEach((address, index) => {
      // Append collectionName, issuer, and group as separate fields in the FormData
      formDatas.append(
        `Collections[${index}][collectionName]`,
        address.collectionName
      );
      formDatas.append(
        `Collections[${index}][taxonId]`,
        address.taxonId.toString()
      );
      formDatas.append(`Collections[${index}][group]`, String(address.group));
    });
    // Loop through the dynamic layers
    values.layers.forEach((trait, index) => {
      formDatas.append(`Traits[${index}][title]`, trait.title);
      formDatas.append(`Traits[${index}][layerNumber]`, trait.layerNumber);
      formDatas.append(`Traits[${index}][require]`, trait.require.toString());

      // Loop through traitImages array and append each image
      trait.traitImages.forEach((image, subIndex) => {
        formDatas.append(`Traits[${index}][traitImages][${subIndex}]`, image);
      });
    });

    values.burnables.forEach((burn, index) => {
      // Append collectionName, issuer, and group as separate fields in the FormData
      formDatas.append(`Burnables[${index}][name]`, burn.name);
      formDatas.append(`Burnables[${index}][taxonId]`, burn.taxonId.toString());
    });
    formDatas.append("TargetMinting[schema]", values?.schema);
    formDatas.append(
      "TargetMinting[targetMintingDescription]",
      values?.targetMintingDescription
    );
    formDatas.append("TargetMinting[collection_name]", values?.collection_name);
    formDatas.append(
      "TargetMinting[collection_family]",
      values?.collection_family
    );
    formDatas.append("TargetMinting[base_name]", values?.base_name);
    formDatas.append("TargetMinting[taxon]", values?.taxon.toString());
    formDatas.append("TargetMinting[nft_type]", values?.nft_type);
    formDatas.append("TargetMinting[issuerId]", values?.issuerId);
    formDatas.append(
      "TargetMinting[transfer_fee_percentage_x_thousand]",
      values?.transfer_fee_percentage_x_thousand.toString()
    );
    // formDatas.append("url",values?.url)
    // Handle the case where background and logo are File objects or null
    setLoading(true);

    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/v2/campaign`,
        formDatas,
        undefined
      );
      console.log(formDatas, "hitting Api");

      setUpdate(!update);
      const input = document.getElementById(
        "background-input"
      ) as HTMLInputElement;
      if (input) {
        input.value = "";
      }
      const logoInput = document.getElementById(
        "logo-input"
      ) as HTMLInputElement;
      if (logoInput) {
        logoInput.value = ""; // Clear the value
      }
      // const traitImg = document.getElementById(
      //   "traitImages-0"
      // ) as HTMLInputElement;
      // if (traitImg) {
      //   traitImg.value = ""; // Clear the value
      // }
      actions.resetForm(); //---form is cleared
      setLoading(false);

      toast.success("Campaign Successfully Added", { autoClose: 3000 });
      setRefresh(true);
      console.log(response, "Noman pc response");
      navigate("/admin-panel");
    } catch (error: any) {
      setLoading(false);

      console.error("An error occurred:", error);
      toast.error(error.response.data.message);
    }
  };
  // words
  const totalWords = 120;
  const [wordCount, setWordCount] = useState(0);

  const fontData = [
    { id: 1, name: "Arial" },
    { id: 2, name: "Verdana" },
    { id: 3, name: "Georgia" },
    // Add more dummy data options as needed
  ];

  // get font size
  const fontSizeData = [
    { id: 1, name: "2px" },
    { id: 2, name: "4px" },
    { id: 3, name: "6px" },
    { id: 4, name: "8px" },
    { id: 5, name: "10px" },
    { id: 6, name: "12px" },
    // Add more dummy data options as needed
  ];
  const campaignTypeData = [
    { id: 1, type: "1-(2 Collections and Potions)" },
    { id: 2, type: "2-(1 Collections and Potions)" },
    { id: 3, type: "3-(Only Potions)" },
  ];
  const groupsData = [
    { id: 1, name: "GroupA" },
    { id: 2, name: "GroupB" },
    // Add more dummy data options as needed
  ];

  const RedButton = styled(Button)`
    && {
      background-color: #d32f2f;
      color: white;
      width:10px
       &:hover {
        background-color: darkred;
      }
    }
  `;

  const GreenButton = styled(Button)`
    && {
      background-color: darkGreen;
      color: white;
      width:10px
      &:hover {
        background-color: green;
      }
    }
  `;

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, [update]);
  // get Font Family from backend
  const [fonts, setFonts] = useState<string[]>([]);

  const [selectedFont, setSelectedFont] = useState<string>("font family");

  const getFontFamily = async () => {
    console.log("Working to get Fonts family");

    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/fonts`,
        {},
        {}
      );
      if (response.data) {
        setFonts(response.data);
      }

      console.log(response);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  // status
  const dummyData = [
    { id: 1, name: "Active" },
    { id: 2, name: "InActive" },    // Add more dummy data options as needed
  ];

  const [options, setFontFamily] = useState<string[]>(
    dummyData.map((item) => item.name)
  );
  const [value, setValue] = React.useState<MuiColorInputValue>("#ffffff");

  // toast notification

  const collectionMargin = "40px";
  const traitMargin = "40px";

  console.log("What is campaign Type", targetcampaignType);

  const checkValues = (val: any, err: any) => {
    console.log("values", val);
    console.log("error", err);
  };

  

  useEffect(() => {
    getFontFamily();
  }, []);
  return (
    <Container sx={{ pt: 2 }}>
      <Paper elevation={2} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          sx={{
            backgroundColor: "white",
            borderRadius: 4,
          }}
        >
          <Typography variant="h5">Campaign</Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  {/* <Button
                    onClick={() => {
                      checkValues(values, errors);
                    }}
                  ></Button> */}
                  {/* ------------- Choose Background------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <FormControl
                      //` fullWidth
                      error={!!touched.background && !!errors.background}
                    >
                      <Box sx={{ position: "relative" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "#555555",
                            fontSize: "14px",
                          }}
                        >
                          Choose Background
                        </Typography>
                        <Input
                          style={{ width: 250 }}
                          type="file"
                          className="custom-file-input-campaign"
                          inputProps={{
                            accept: "image/*",
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(event);
                              const file =
                                event.currentTarget.files &&
                                event.currentTarget.files[0];
                              if (file) {
                                setFieldValue("background", file);
                              }
                            },
                          }}
                          name="background"
                          id="background-input"
                        />
                      </Box>
                      {touched.background && !!errors.background && (
                        <FormHelperText sx={{ color: "red" }}>
                          {errors.background}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={12} xs={12}>
                    <FormControl
                      // fullWidth
                      error={!!touched.logo && !!errors.logo}
                    >
                      <Box sx={{ position: "relative" }}>
                        {/* ------------- Choose Logo------------- */}
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "#555555",
                            fontSize: "14px",
                          }}
                        >
                          Choose Logo
                        </Typography>
                        <Input
                          type="file"
                          style={{ width: 250 }}
                          className="custom-file-input-campaign"
                          inputProps={{
                            accept: "image/*",
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(event);
                              const file =
                                event.currentTarget.files &&
                                event.currentTarget.files[0];
                              if (file) {
                                setFieldValue("logo", file);
                              }
                            },
                          }}
                          name="logo"
                          id="logo-input"
                        />
                      </Box>
                      {touched.logo && !!errors.logo && (
                        <FormHelperText sx={{ color: "red" }}>
                          {errors.logo}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ------------- Name------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Name
                    </Typography>
                    <div>
                      <Field name="name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            size="small"
                            placeholder="Name"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------- Campaign Type ------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Campaign Type
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        // size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        displayEmpty
                        placeholder="Campaign Type"
                        name="campaignType"
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        // renderValue={(selected) => {
                        //   if (selected.length === 0) {
                        //     return <em>Campaign Type</em>;
                        //   }

                        //   return selected;
                        // }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <Typography>Campaign Type</Typography>;
                          }
                          const selectedCampaign = campaignTypeData.find(
                            (campaignTypeData) =>
                              campaignTypeData?.id === Number(selected)
                          );
                          return selectedCampaign ? (
                            selectedCampaign.type
                          ) : (
                            <em>Campaigns Type</em>
                          );
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        value={values?.campaignType}
                        sx={{ width: "250px" }}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedType = e.target.value as string;
                          setcampaignType(selectedType);
                        }}
                        error={
                          touched.campaignType && Boolean(errors.campaignType)
                        }
                      >
                        {campaignTypeData?.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.type}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.campaignType && errors.campaignType && (
                        <FormHelperText error>
                          {errors.campaignType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ------------- Title------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Title
                    </Typography>
                    <div>
                      <Field name="title">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Title"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------- Status ------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Status
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        displayEmpty
                        name="status"
                        sx={{ width: "250px" }}
                        value={values?.status}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Status</em>;
                          }

                          return selected;
                        }}
                        error={touched.status && Boolean(errors.status)}
                      >
                        {dummyData.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.status && errors.status && (
                        <FormHelperText error>{errors.status}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ------------- Font Family ------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Font Family
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        sx={{ width: "250px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="fontFamily"
                        displayEmpty
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        value={values?.fontFamily}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Font Family</em>;
                          }

                          return selected;
                        }}
                        error={
                          touched?.fontFamily && Boolean(errors?.fontFamily)
                        }
                      >
                        {fonts?.map((font) => (
                          <MenuItem key={font} value={font}>
                            {font}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched?.fontFamily && errors?.fontFamily && (
                        <FormHelperText error>
                          {errors.fontFamily}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ------------- Font size ------------- */}

                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Font Size
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        sx={{ width: "250px" }}
                        displayEmpty
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="fontSize"
                        value={values?.fontSize}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Font Size</em>;
                          }

                          return selected;
                        }}
                        error={touched?.fontSize && Boolean(errors?.fontSize)}
                      >
                        {fontSizeData.map((fontSize) => (
                          <MenuItem key={fontSize.id} value={fontSize.name}>
                            {fontSize.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched?.fontSize && errors?.fontSize && (
                        <FormHelperText error>{errors.fontSize}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/*---------------- Typogrpahy --------------- */}
                  {/*---------------- Theme --------------- */}
                  <Grid xs={4} item>
                    <FormControl>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#555555",
                          fontSize: 12,
                        }}
                      >
                        Background Color
                      </Typography>
                      <MuiColorInput
                        size="small"
                        sx={{ width: "250px" }}
                        value={values?.backgroundColor}
                        onChange={(color) =>
                          setFieldValue("backgroundColor", color)
                        }
                        format={"rgb"}
                      />
                      {touched?.backgroundColor && errors?.backgroundColor && (
                        <Typography variant="caption" color="error">
                          {errors.backgroundColor}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid lg={4} sm={12} xs={12} item>
                    <FormControl>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#555555",
                          fontSize: 12,
                        }}
                      >
                        Button Color
                      </Typography>
                      <MuiColorInput
                        size="small"
                        sx={{ width: "250px" }}
                        value={values?.buttonColor}
                        onChange={(color) =>
                          setFieldValue("buttonColor", color)
                        }
                        format={"rgb"}
                      />
                      {touched.buttonColor && errors?.buttonColor && (
                        <Typography variant="caption" color="error">
                          {errors.buttonColor}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ------------- Lanch Date------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Launch Date
                    </Typography>
                    <Field name="launch_Date" style={{ width: "250px" }}>
                      {({ field, form }: { field: any; form: any }) => (
                        <div className="custom-datepicker-wrapper">
                          <DatePicker
                            showIcon
                            selected={field.value || new Date()}
                            onChange={(date: Date) =>
                              form.setFieldValue("launch_Date", date)
                            }
                            minDate={new Date()}
                            maxDate={addMonths(new Date(), 5)}
                            className="custom-datepicker"
                          />
                          <ErrorMessage
                            name="launch_Date"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      )}
                    </Field>
                  </Grid>
                  {/* ------------- End Date------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      End Date
                    </Typography>

                    <Field name="end_Date" sx={{ width: "250px" }}>
                      {({ field, form }: { field: any; form: any }) => (
                        <div
                          className="custom-datepicker-wrapper"
                          style={{ display: "table-cell" }}
                        >
                          <DatePicker
                            showIcon
                            selected={field.value || null}
                            dateFormat="MM/dd/yyyy"
                            value={
                              (field.value &&
                                format(field.value, "MM/dd/yyyy")) ||
                              "MM/DD/YYYY"
                            }
                            onChange={(date: Date) =>
                              form.setFieldValue("end_Date", date)
                            }
                            minDate={
                              form.values.launch_Date
                                ? addDays(form.values.launch_Date, 1)
                                : null
                            }
                            // minDate={new Date()}
                            maxDate={addMonths(new Date(), 5)}
                            className="custom-datepicker"
                          />
                          <div>
                            <ErrorMessage
                              name="end_Date"
                              component="div"
                              className="error-message-date"
                            />
                          </div>
                        </div>
                      )}
                    </Field>
                  </Grid>
                  {/* ----------------Description----------------- */}
                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Description
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <TextField
                        size="small"
                        sx={{ marginTop: "2px", width: "91%" }}
                        type="text"
                        multiline
                        placeholder="Description"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          const characterCount = event.target.value.length;
                          setWordCount(characterCount);
                          setFieldValue("description", event.target.value);
                        }}
                        value={values?.description}
                        name="description"
                        inputProps={{
                          style: {
                            height: "90px",
                            width: "100%",
                            paddingRight: "70px", // Add space for the word count
                          },
                          maxLength: 120,
                        }}
                        error={!!touched.description && !!errors.description}
                        helperText={touched.description && !!errors.description}
                      />
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "8px",
                          right: "109px",
                          color: "#555555",
                          fontSize: "16px",
                        }}
                      >
                        {wordCount} / {totalWords}
                      </Typography>
                    </div>
                  </Grid>
                  {/* -------------------------Collection Form------------------- */}
                  {targetcampaignType && targetcampaignType == "1" ? (
                    <>
                      <Grid
                        lg={12}
                        sm={12}
                        xs={12}
                        item
                        sx={{ marginTop: collectionMargin }}
                      >
                        <Typography variant="h5">Collections</Typography>
                      </Grid>

                      <Grid lg={12} sm={12} xs={12} item>
                        <Box sx={{ width: "91%" }}>
                          <FieldArray name="collectionAddresses">
                            {({ push, remove }) => (
                              <div>
                                {values.collectionAddresses.map((_, index) => (
                                  <Box
                                    key={index}
                                    style={{
                                      display: "flex",
                                      // alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Collection Name
                                      </Typography>
                                      <div>
                                        <Field
                                          name={`collectionAddresses[${index}].collectionName`}
                                          render={({
                                            field,
                                            meta,
                                          }: FieldProps<string>) => (
                                            <TextField
                                              id={`collectionName-${index}`}
                                              type="text"
                                              placeholder="Collection Name"
                                              size="small"
                                              sx={{
                                                marginTop: "2px",
                                                width: "250px",
                                              }}
                                              {...field}
                                              error={
                                                meta?.touched && !!meta?.error
                                              }
                                              helperText={
                                                meta?.touched && meta?.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Taxon Id
                                      </Typography>
                                      <div>
                                        <Field
                                          name={`collectionAddresses[${index}].taxonId`}
                                          render={({
                                            field,
                                            meta,
                                          }: FieldProps<string>) => (
                                            <TextField
                                              id={`taxonId-${index}`}
                                              type="text"
                                              placeholder="Taxon Id"
                                              size="small"
                                              sx={{
                                                marginTop: "2px",
                                                width: "250px",
                                              }}
                                              {...field}
                                              error={
                                                meta?.touched && !!meta?.error
                                              }
                                              helperText={
                                                meta?.touched && meta?.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div style={{ paddingTop: "3px" }}>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Groups
                                      </Typography>
                                      <FormControl
                                        style={{ minWidth: 120 }}
                                        size="small"
                                        // error={
                                        //   touched?.collectionAddresses &&
                                        //   touched?.collectionAddresses[index] &&
                                        //   Boolean(
                                        //     (errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group
                                        //   )
                                        // }
                                      >
                                        <Select
                                          name={`collectionAddresses[${index}].group`}
                                          displayEmpty
                                          onChange={handleChange}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{ width: "250px" }}
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <Typography>Groups</Typography>
                                              );
                                            }
                                            const selectedGroups =
                                              groupsData.find(
                                                (groupsData) =>
                                                  groupsData?.id ===
                                                  Number(selected)
                                              );
                                            return selectedGroups ? (
                                              selectedGroups?.name
                                            ) : (
                                              <Typography>Groups</Typography>
                                            );
                                          }}
                                        >
                                          <MenuItem value={1}>Group A</MenuItem>
                                          <MenuItem value={2}>Group B</MenuItem>
                                        </Select>
                                      </FormControl>
                                      {/* <div>temp error</div> */}
                                      {/* <FormHelperText className="error-message">
                                {(errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group}
                              </FormHelperText> */}
                                    </div>
                                    <div style={{ paddingTop: "18px" }}>
                                      <RedButton
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <DeleteIcon />
                                      </RedButton>
                                    </div>
                                  </Box>
                                ))}
                                <GreenButton
                                  type="button"
                                  sx={{ marginLeft: "2px", marginTop: "9px" }}
                                  onClick={() =>
                                    push({
                                      collectionName: "",
                                      taxonId: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </GreenButton>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </>
                  ) : targetcampaignType && targetcampaignType == "2" ? (
                    <>
                      <Grid
                        lg={12}
                        sm={12}
                        xs={12}
                        item
                        sx={{ marginTop: collectionMargin }}
                      >
                        <Typography variant="h5">Collections</Typography>
                      </Grid>

                      <Grid lg={12} sm={12} xs={12} item>
                        <Box sx={{ width: "91%" }}>
                          <FieldArray name="collectionAddresses">
                            {({ push, remove }) => (
                              <div>
                                {values.collectionAddresses.map((_, index) => (
                                  <Box
                                    key={index}
                                    style={{
                                      display: "flex",
                                      // alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Collection Name
                                      </Typography>
                                      <div>
                                        <Field
                                          name={`collectionAddresses[${index}].collectionName`}
                                          render={({
                                            field,
                                            meta,
                                          }: FieldProps<string>) => (
                                            <TextField
                                              id={`collectionName-${index}`}
                                              type="text"
                                              placeholder="Collection Name"
                                              size="small"
                                              sx={{
                                                marginTop: "2px",
                                                width: "250px",
                                              }}
                                              {...field}
                                              error={
                                                meta?.touched && !!meta?.error
                                              }
                                              helperText={
                                                meta?.touched && meta?.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Taxon Id
                                      </Typography>
                                      <div>
                                        <Field
                                          name={`collectionAddresses[${index}].taxonId`}
                                          render={({
                                            field,
                                            meta,
                                          }: FieldProps<string>) => (
                                            <TextField
                                              id={`taxonId-${index}`}
                                              type="text"
                                              placeholder="Taxon Id"
                                              size="small"
                                              sx={{
                                                marginTop: "2px",
                                                width: "250px",
                                              }}
                                              {...field}
                                              error={
                                                meta?.touched && !!meta?.error
                                              }
                                              helperText={
                                                meta?.touched && meta?.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div style={{ paddingTop: "3px" }}>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Groups
                                      </Typography>
                                      <FormControl
                                        style={{ minWidth: 120 }}
                                        size="small"
                                        // error={
                                        //   touched?.collectionAddresses &&
                                        //   touched?.collectionAddresses[index] &&
                                        //   Boolean(
                                        //     (errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group
                                        //   )
                                        // }
                                      >
                                        <Select
                                          name={`collectionAddresses[${index}].group`}
                                          displayEmpty
                                          onChange={handleChange}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{ width: "250px" }}
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <Typography>Groups</Typography>
                                              );
                                            }
                                            const selectedGroups =
                                              groupsData.find(
                                                (groupsData) =>
                                                  groupsData?.id ===
                                                  Number(selected)
                                              );
                                            return selectedGroups ? (
                                              selectedGroups?.name
                                            ) : (
                                              <Typography>Groups</Typography>
                                            );
                                          }}
                                        >
                                          <MenuItem value={1}>Group A</MenuItem>
                                          <MenuItem value={2} disabled>
                                            Group B
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                      {/* <FormHelperText
                                      className="error-message">
                                {(errors?.collectionAddresses as FormikErrors<Collection>[])[index]?.group}
                              </FormHelperText> */}
                                    </div>
                                    <div style={{ paddingTop: "18px" }}>
                                      <RedButton
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <DeleteIcon />
                                      </RedButton>
                                    </div>
                                  </Box>
                                ))}
                                <GreenButton
                                  type="button"
                                  sx={{ marginLeft: "2px", marginTop: "9px" }}
                                  onClick={() =>
                                    push({
                                      collectionName: "",
                                      taxonId: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </GreenButton>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </>
                  ) : targetcampaignType && targetcampaignType == "3" ? (
                    <div></div>
                  ) : (
                    <>
                      <Grid
                        lg={12}
                        sm={12}
                        xs={12}
                        item
                        sx={{ marginTop: collectionMargin }}
                      >
                        <Typography variant="h5">Collections</Typography>
                      </Grid>
                      <Grid lg={12} sm={12} xs={12} item>
                        <Box sx={{ width: "91%" }}>
                          <FieldArray name="collectionAddresses">
                            {({ push, remove }) => (
                              <div>
                                {values.collectionAddresses.map((_, index) => (
                                  <Box
                                    key={index}
                                    style={{
                                      display: "flex",
                                      // alignItems: "center",
                                      justifyContent: "space-between",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Collection Name
                                      </Typography>
                                      <div>
                                        <Field
                                          name={`collectionAddresses[${index}].collectionName`}
                                          render={({
                                            field,
                                            meta,
                                          }: FieldProps<string>) => (
                                            <TextField
                                              id={`collectionName-${index}`}
                                              type="text"
                                              placeholder="Collection Name"
                                              size="small"
                                              sx={{
                                                marginTop: "2px",
                                                width: "250px",
                                              }}
                                              {...field}
                                              error={
                                                meta.touched && !!meta.error
                                              }
                                              helperText={
                                                meta.touched && meta.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Taxon Id
                                      </Typography>
                                      <div>
                                        <Field
                                          name={`collectionAddresses[${index}].taxonId`}
                                          render={({
                                            field,
                                            meta,
                                          }: FieldProps<string>) => (
                                            <TextField
                                              id={`taxonId-${index}`}
                                              type="text"
                                              placeholder="Taxon Id"
                                              size="small"
                                              sx={{
                                                marginTop: "2px",
                                                width: "250px",
                                              }}
                                              {...field}
                                              error={
                                                meta?.touched && !!meta?.error
                                              }
                                              helperText={
                                                meta?.touched && meta?.error
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>

                                    <div style={{ paddingTop: "3px" }}>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#555555",
                                          fontSize: 12,
                                        }}
                                      >
                                        Groups
                                      </Typography>
                                      <FormControl
                                        style={{ minWidth: 120 }}
                                        size="small"
                                      >
                                        <Select
                                          name={`collectionAddresses[${index}].group`}
                                          displayEmpty
                                          onChange={handleChange}
                                          input={<OutlinedInput />}
                                          MenuProps={MenuProps}
                                          inputProps={{
                                            "aria-label": "Without label",
                                          }}
                                          sx={{ width: "250px" }}
                                          renderValue={(selected) => {
                                            if (!selected) {
                                              return (
                                                <Typography>Groups</Typography>
                                              );
                                            }
                                            const selectedGroups =
                                              groupsData.find(
                                                (groupsData) =>
                                                  groupsData?.id ===
                                                  Number(selected)
                                              );
                                            return selectedGroups ? (
                                              selectedGroups?.name
                                            ) : (
                                              <Typography>Groups</Typography>
                                            );
                                          }}
                                        >
                                          <MenuItem value={1}>Group A</MenuItem>
                                          <MenuItem value={2}>Group B</MenuItem>
                                        </Select>
                                      </FormControl>
                                      {/* <ErrorMessage
                                        name={`collectionAddresses[${index}].group`}
                                        component="div"
                                        className="error-message"
                                      /> */}
                                    </div>
                                    <div style={{ paddingTop: "18px" }}>
                                      <RedButton
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <DeleteIcon />
                                      </RedButton>
                                    </div>
                                  </Box>
                                ))}
                                <GreenButton
                                  type="button"
                                  sx={{ marginLeft: "2px", marginTop: "9px" }}
                                  onClick={() =>
                                    push({
                                      collectionName: "",
                                      taxonId: "",
                                    })
                                  }
                                >
                                  <AddIcon />
                                </GreenButton>
                              </div>
                            )}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </>
                  )}
                  {/* -----------------------Traits-------------------- */}
                  {/*---------------- Title --------------- */}
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    sx={{ marginTop: traitMargin }}
                  >
                    <Typography variant="h5">Traits </Typography>
                  </Grid>
                  <Grid lg={12} sm={12} xs={12} item>
                    <Box sx={{ width: "91%" }}>
                      <FieldArray
                        name="layers"
                        render={(arrayHelpers ) => (
                          <div>
                            {values?.layers?.map((layer, index) => (
                              <div key={layer.id} >
                                <div
                                style={{
                                  display: "flex",
                                  // alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: "1rem",
                                }}
                              >
                                {/* Layer Title */}
                                <div>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#555555",
                                      fontSize: 12,
                                    }}
                                  >
                                    Layer Title
                                  </Typography>
                                  <div>
                                    <Field
                                      name={`layers[${index}].title`}
                                      render={({
                                        field,
                                        meta,
                                      }: FieldProps<string>) => (
                                        <TextField
                                          id={`title-${layer.id}`}
                                          type="text"
                                          placeholder="eg: Background"
                                          size="small"
                                          sx={{
                                            marginTop: "2px",
                                            width: "250px",
                                          }}
                                          {...field}
                                          error={meta.touched && !!meta.error}
                                          helperText={
                                            meta.touched && meta.error
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                </div>


                                {/* Layer Number */}
                                <div>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#555555",
                                      fontSize: 12,
                                    }}
                                  >
                                    Layer Number
                                  </Typography>
                                  <div>
                                    <Field
                                      name={`layers[${index}].layerNumber`}
                                      render={({
                                        field,
                                        meta,
                                      }: FieldProps<string>) => (
                                        <TextField
                                          id={`layerNumber-${layer.id}`}
                                          type="text"
                                          placeholder="Layer Number"
                                          size="small"
                                          sx={{
                                            marginTop: "2px",
                                            width: "250px",
                                          }}
                                          {...field}
                                          error={meta.touched && !!meta.error}
                                          helperText={
                                            meta.touched && meta.error
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                                {/* Multiple Trait Images */}
                                <div style={{ paddingTop: "3px" }}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#555555",
                                      fontSize: 12,
                                    }}
                                  >
                                    Choose Multiple Trait Images
                                  </Typography>
                                  <input
                                    style={{ width: "220px" }}
                                    type="file"
                                    multiple
                                    // id={`traitImages-${index}`}
                                    id={`traitImages-${layer.id}`}
                                    name={`layers[${index}].traitImages`}
                                    // className="custom-file-input-traits"
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const files = event.currentTarget.files;
                                      if (files) {
                                        const fileArray = Array.from(files);
                                        setFieldValue(
                                          `layers[${index}].traitImages`,
                                          fileArray
                                        );
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`layers[${index}].traitImages`}
                                    component="div"
                                    className="error-message"
                                  />
                                </div>
                                {/* required/not required NFT field */}
                                <div style={{ paddingTop: "18px" }}>
                                  <Field
                                    name={`layers[${index}].require`}
                                    render={({
                                      field,
                                      meta,
                                    }: FieldProps<number>) => (
                                      <div>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                            id={`require-${layer.id}`}
                                              checked={field.value === 1}
                                              onChange={(event) => {
                                                field.onChange({
                                                  target: {
                                                    name: field.name,
                                                    value: event.target.checked
                                                      ? 1
                                                      : 0,
                                                  },
                                                });
                                              }}
                                              name={field.name}
                                              inputProps={{
                                                "aria-label": "require-toggle",
                                              }}
                                            />
                                          }
                                          label="Required"
                                        />
                                      </div>
                                    )}
                                  />
                                </div>

                                <div style={{ paddingTop: "18px" }}>
                                  {/* Remove button for each layer */}
                                  <RedButton
                                    type="button"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </RedButton>
                                </div>
                                </div>
                              </div>
                            ))}
                            {/* Add new layer button */}
                            <GreenButton
                              type="button"
                              sx={{ marginLeft: "2px", marginTop: "9px" }}
                              onClick={() =>
                                arrayHelpers.push({
                                  id: uniqueId(),
                                  title: "",
                                  layerNumber: "",
                                  require: 1,
                                  traitImages: [],
                                })
                              }
                            >
                              <AddIcon />
                            </GreenButton>
                          </div>
                        )}
                      />
                    </Box>
                  </Grid>

                  {/* ------------------------Burnables----------------------------------- */}
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    sx={{ marginTop: traitMargin }}
                  >
                    <Typography variant="h5">Burnables</Typography>
                  </Grid>
                  <Grid lg={12} sm={12} xs={12} item>
                    <Box sx={{ width: "65%" }}>
                      <FieldArray name="burnables">
                        {({ push, remove }) => (
                          <Box>
                            {values.burnables.map((_, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  // alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: "1rem",
                                }}
                              >
                                <div>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#555555",
                                      fontSize: 12,
                                    }}
                                  >
                                    Name
                                  </Typography>
                                  <div>
                                    <Field
                                      name={`burnables[${index}].name`}
                                      render={({
                                        field,
                                        meta,
                                      }: FieldProps<string>) => (
                                        <TextField
                                          id={`name-${index}`}
                                          type="text"
                                          placeholder="Name"
                                          size="small"
                                          sx={{
                                            marginTop: "2px",
                                            width: "250px",
                                          }}
                                          {...field}
                                          error={meta.touched && !!meta.error}
                                          helperText={
                                            meta.touched && meta.error
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                                <div style={{ paddingTop: "3px" }}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "#555555",
                                      fontSize: 12,
                                    }}
                                  >
                                    Taxon Id
                                  </Typography>
                                  <div>
                                    <Field
                                      name={`burnables[${index}].taxonId`}
                                      render={({
                                        field,
                                        meta,
                                      }: FieldProps<string>) => (
                                        <TextField
                                          id={`taxonId-${index}`}
                                          type="number"
                                          placeholder="Taxon Id"
                                          size="small"
                                          sx={{
                                            marginTop: "2px",
                                            width: "250px",
                                            height: "36px",
                                          }}
                                          {...field}
                                          error={meta.touched && !!meta.error}
                                          helperText={
                                            meta.touched && meta.error
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div style={{ paddingTop: "18px" }}>
                                  <RedButton
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </RedButton>
                                </div>
                              </div>
                            ))}

                            <GreenButton
                              type="button"
                              sx={{ marginLeft: "2px", marginTop: "9px" }}
                              onClick={() =>
                                push({
                                  name: "",
                                  taxonId: "",
                                })
                              }
                            >
                              <AddIcon />
                            </GreenButton>
                          </Box>
                        )}
                      </FieldArray>
                    </Box>
                  </Grid>
                  {/* ------------------------- Target Minting---------------------------- */}
                  <Grid
                    lg={12}
                    sm={12}
                    xs={12}
                    item
                    sx={{ marginTop: traitMargin }}
                  >
                    <Typography variant="h5">Target Minting</Typography>
                  </Grid>
                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      NFT Type
                    </Typography>
                    <div>
                      <Field name="nft_type">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Nft Type"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------collection_name------------------- */}
                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Collection Name
                    </Typography>
                    <div>
                      <Field name="collection_name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Collection Name"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------collection_family------------------- */}
                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Collection Family
                    </Typography>
                    <div>
                      <Field name="collection_family">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Collection Family"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------schema------------------- */}
                  <Grid item lg={6} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Schema
                    </Typography>
                    <div>
                      <Field name="schema">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Schema"
                            size="small"
                            sx={{ marginTop: "2px", width: "85%" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------Issuer------------------- */}
                  <Grid lg={6} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Issuer
                    </Typography>
                    <div>
                      <Field name="issuerId">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Issuer"
                            size="small"
                            sx={{ marginTop: "2px", width: "81%" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>

                  {/* ------------------base_name------------------- */}
                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Base Name
                    </Typography>
                    <div>
                      <Field name="base_name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Base Name"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------taxon------------------- */}
                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Taxon
                    </Typography>
                    <div>
                      <Field name="taxon">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="number"
                            placeholder="Taxon"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------------transfer_fee_percentage_x_thousand------------------- */}
                  <Grid lg={4} sm={12} xs={12} item>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Transfer Fees
                    </Typography>
                    <div>
                      <Field name="transfer_fee_percentage_x_thousand">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="number"
                            placeholder="Transfer Fee"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------- Description ------------- */}
                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Description
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <TextField
                        size="small"
                        sx={{ marginTop: "2px", width: "91%" }}
                        type="text"
                        multiline
                        placeholder="Description"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          const characterCount = event.target.value.length;
                          setWordCount(characterCount);
                          setFieldValue(
                            "targetMintingDescription",
                            event.target.value
                          );
                        }}
                        value={values.targetMintingDescription}
                        name="targetMintingDescription"
                        inputProps={{
                          style: {
                            height: "90px",
                            width: "100%",
                            paddingRight: "70px", // Add space for the word count
                          },
                          maxLength: 200,
                        }}
                        error={
                          !!touched.targetMintingDescription &&
                          !!errors.targetMintingDescription
                        }
                        helperText={
                          touched.targetMintingDescription &&
                          !!errors.targetMintingDescription
                        }
                      />
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "8px",
                          right: "109px",
                          color: "#555555",
                          fontSize: "16px",
                        }}
                      >
                        {wordCount} / {totalWords}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="start" mt="40px">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#454545",
                      width: "100px",
                      height: "40px",
                    }}
                    // disabled={!isValid || isSubmitting}
                  >
                    {loading === false ? (
                      "Create"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default CampaignInfoComp;
