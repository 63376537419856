import React from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Modal,
    Paper,
    TextField,
    Typography,
  } from "@mui/material";
  import { useState, useEffect } from "react";
  import DynamicTableView from "../Traits Components/DynamicTableView/DynamicTableView";
  import { ToastContainer, toast } from "react-toastify";
  import { Base_Url, axiosRequestLocal } from "../../api/api";
import { useNavigate } from 'react-router-dom';
import { Field, FieldProps, Formik, FormikHelpers } from 'formik';
import * as Yup from "yup";


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    backgroundColor: "white",
    // bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  interface FormDataInterface {
    userAccount: string;
  }


  
interface Column {
    id: "id" | "userAccount" | "action";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: any) => string | JSX.Element;
  }
  const columns: Column[] = [
    { id: "id", label: "Id", minWidth: 100 },
    { id: "userAccount", label: "UserAccount", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  
const AdminManagementComp = () => {
  
   // use States
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const accountAddress = localStorage.getItem("accnum");

   //  Table View States
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);
 
   // Confirm dialogue states
   const [confirmOpen, setConfirmOpen] = useState(false);
   const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion
   const [isEditModalOpen, setEditModalOpen] = useState(false);
 
   const initialValues: FormDataInterface = {
        userAccount: "", 
    }

   const dummyData = [
     {
       id: 100,
       userAccount: "rnB8jr6kZ3HZqcNeTYpvV3HnjUeHKxTLgK",
     },
   ];
 
   // UsefNfts
   const [adminDetails, setAdminDetails] = useState<any[]>(dummyData);
 

  
  const validationSchema = Yup.object().shape({
    userAccount: Yup.string()
    // .matches(/^r[0-9a-zA-Z]{33}$/, "Admin Address is not in the correct format")
    .required("Admin Address is required"),
  })
  
  
  


   //------------------- Functions ----------------------
   const viewDetails = async () => {
     console.log("Working to getting NFTS minted");
     setLoading(true);
     try {
       const response: any = await axiosRequestLocal(
         "get",
         Base_Url + `/api/admin/view-details`,
         {},
         {}
       );
       console.log("Data", response.data.users);
       if (response.data) {
         const viewadminDetails = response.data.users;
         setAdminDetails(viewadminDetails);
         console.log("admin details", viewadminDetails);
       }
       setLoading(false);
     } catch (error) {
       console.log("Error fetching data:", error);
     }
   };
 
   // Table View
   const handleChangePage = (event: unknown, newPage: number) => {
     setPage(newPage);
   };
   const handleChangeRowsPerPage = (
     event: React.ChangeEvent<HTMLInputElement>
   ) => {
     setRowsPerPage(+event.target.value);
     setPage(0);
   };
   //   Delete Prop
   const handleDelete = (id: number) => {
     setSelectedItemId(id);
     setConfirmOpen(true);
   };
 
   const handleEdit = (id: number) => {
     // Find the collection data with the given id
     console.log("No edit here sorry");
   };
 
   // delete the traitContent image
   const deleteAdmin = async (id: number) => {
     try {
       const response: any = await axiosRequestLocal(
         "post",
         Base_Url + `/api/admin/delete/${id}`,
         {},
         {}
       );
       toast.success("Admin Successfully Deleted", { autoClose: 3000 });
       viewDetails();
     } catch (error: any) {
       console.log("Error fetching data:", error);
       toast.error(error.response.data.message);
     }
   };

   const checkAdmin = async (accountAddress:string): Promise<void> => {
    try {
      console.log('LOGGED --->',accountAddress)
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        console.log(URL);
        const response: any = await axiosRequestLocal("get", URL);
        console.log("Response of IsAdmin", response.data.status);
        if(response.data && response.data.status){
          navigate(`/admin-panel/admins-management`);
        }else{
          console.log('--------------->')
          navigate('/');
        }
      }else{
        navigate('/');
      }
    } catch (error) {

      console.log("Response of IsAdmin", error);
      navigate('/');
      console.log(error);
    }
  };

   // POST API for Form on add button clicked
   const handleFormSubmit = async (
    values: FormDataInterface,
    actions: FormikHelpers<FormDataInterface>
  ) => {
    const payload = {
      userAccount:values.userAccount,
    };

    // Handle the case where background and logo are File objects or null
    setLoading(true);
    console.log("whats in payload",payload);

    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/create`,
        payload,
        undefined
      );
      actions.resetForm();
      viewDetails();
      toast.success("Admin Successfully Added", { autoClose: 3000 });
      setLoading(false);
      setEditModalOpen(false);
    } catch (error: any) {
      setLoading(false);

      console.error("An error occurred:", error);
      toast.error(error.response.data.message);
    }
  };

 
   // ------------------------------Use Effects---------------------------------
 
   useEffect(() => {
    if(accountAddress){

        checkAdmin(accountAddress);
      }
     viewDetails();
   }, []);
 
   return (
     <Container sx={{ borderRadius: 4, mt: 2 }}>
       <Paper elevation={4} sx={{ borderRadius: 4 }}>
         <Box
           padding={3}
           // borderRadius={5}
           sx={{
             borderRadius: 4,
             backgroundColor: "white",
           }}
         >
           <Typography variant="h3">Admin Management</Typography>
           <Grid container spacing={2} sx={{ mt: "2px" }}>
             {/*---------------- Select Trait--------------- */}
           </Grid>
           <Box sx={{display:"flex",justifyContent:"flex-end"}}>
           <Button
             onClick={() => {
                setEditModalOpen(true);
              }}
           variant='contained'
           >
            Create Admin
           </Button>
           </Box>
           <Typography mt={"20px"}>List of Admin </Typography>
           <Box>
             <Box sx={{ marginTop: "10px" }}>
               {loading ? (
                 <Box display="flex" justifyContent="center" mt={3}>
                   <CircularProgress size={70} sx={{ color: "black" }} />
                 </Box>
               ) : (
                 <DynamicTableView
                   columns={columns}
                   data={adminDetails}
                   loading={loading}
                   page={page}
                   rowsPerPage={rowsPerPage}
                   handleChangePage={handleChangePage}
                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                   handleDelete={handleDelete}
                   showEditIcon={false}
                   handleEdit={handleEdit}
                   confirmOpen={confirmOpen}
                   setConfirmOpen={setConfirmOpen}
                   selectedItemId={selectedItemId}
                   deleteFunction={deleteAdmin}
                 />
               )}
             </Box>
           </Box>
         </Box>
         {isEditModalOpen && (
          <Modal
            open={isEditModalOpen}
            onClose={() => {
              setEditModalOpen(false);
            //   setSelectedRowData(null); // Clear selected row data when closing the modal
            }}
          >
            <Paper elevation={4} sx={{ ...style, width: 350, borderRadius: 4 }}>
              <Typography variant="h5">Create Admin</Typography>

              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={validationSchema}
              >
                      {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                  {/* Your form fields go here */}
                  <Field
                    name="userAccount"
                    render={({ field, meta }: FieldProps<string>) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        placeholder='User Account'
                        margin="normal"
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  />
             
                  <Box sx={{ marginTop: "10px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: "10px" }}
                    >
                      Create
                    </Button>
                    <Button
                      onClick={() => {
                        setEditModalOpen(false);
                      }}
                      variant="contained"
                      color="error"
                      sx={{ marginTop: "10px", marginLeft: "5px" }}
                    >
                      Close
                    </Button>
                  </Box>
                </form>
                  )}
              </Formik>
            </Paper>
          </Modal>
        )}
         <ToastContainer />
       </Paper>

     </Container>
  );
}

export default AdminManagementComp
