import BreedingComp from "../../Components/BredingComp/Breeding";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileViewBreeding from "../../Components/Admin-Scenes/MobileViewBreeding/MobileViewBreeding";
import UserCampaign from "../../Components/user/Campaign/Single";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CampaignType } from "../../types/campaignTypes";
import { setCampaigns } from "../../redux/CampaignsReducer/CampaignsReducer";
import { Base_Url, axiosRequestLocal } from "../../Components/api/api";

const Breeding = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  console.log("What is campaign campaginId", id);
  const [campaign, setCampaign] = useState<CampaignType | null>(null);
  const [loading, setLoading] = useState(true);

  const getCampaignId = async (campaginId: string | undefined) => {
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${campaginId}`
      );

      dispatch(setCampaigns(response.data.campaign));
      setCampaign(response.data.campaign);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the data is fetched (successful or not)
    }
  };

  useEffect(() => {
    getCampaignId(id);
  }, []);

  const mobileViewCheck = useMediaQuery("(min-width:1023px)");

  return (
    <>
      {mobileViewCheck && campaign ? (
        <UserCampaign campaignState={campaign} />
      ) : !mobileViewCheck && campaign ? (
        <MobileViewBreeding campaignState={campaign} />
      ) : null}
    </>
  );
};

export default Breeding;
