import { useLocation, useParams } from "react-router-dom";
import DesginGrids from "../../Components/Admin-Scenes/Character Design Comp/DesignGrids";
import MobilView from "../../Components/Admin-Scenes/CharacterDesignMobileView/CharacterDesignMobileView";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CampaignType } from "../../types/campaignTypes";
import { Base_Url, axiosRequestLocal } from "../../Components/api/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CharacterDesign = () => {
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );
  console.log("Campaign selector---->", campaignsSelector);
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");

  // const [campaign, setCampaign] = useState<CampaignType | null>(null);
  // const getCampaignId = async (id: string | undefined) => {
  //   console.log("Working to get campaign Id");

  //   try {
  //     const response: any = await axiosRequestLocal(
  //       "get",
  //       Base_Url + `/api/admin/campaign/${id}`,
  //       {},
  //       {}
  //     );
  //     console.log("What is console", response.data.campaign);
  //     setCampaign(response.data.campaign);
  //   } catch (error) {
  //     console.log("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getCampaignId(id);
  // }, []);

  // setCampaign(campaignsSelector);

  return (
    <>
      {mobileViewCheck && campaignsSelector ? (
        <DesginGrids campaignState={campaignsSelector} />
      ) : !mobileViewCheck && campaignsSelector ? (
        <MobilView campaignState={campaignsSelector} />
      ) : null}
    </>
  );
};

export default CharacterDesign;
