import { combineReducers } from "redux";
import LoginReducers from "../LoginReducers/LoginReducers";
import SnackBarReducer from "../Snackbar/SnackbarReducder";
import NFTReducer from "../NFTReducer/NFTReducer";
import GenderReducer from "../GenderReducer/GenderReducer";
import QueueProcessReducer from "../QueueProcess/QueueProcessReducer";
import LoaderReducer from "../LoaderReducer/LoaderReducer";
import PotionReducer from "../PotionReducer/PotionReducer";
import MinterSlice from "../TargetMintReducer/TargetMintReducer";
import SocketReducer from "../SocketReducer/SocketReducer";
import CampaignsReducer from "../CampaignsReducer/CampaignsReducer";

export const reducer = combineReducers({
  mint: MinterSlice,
  xumm: LoginReducers,
  snackbar: SnackBarReducer,
  nfts: NFTReducer,
  gender: GenderReducer,
  queue: QueueProcessReducer,
  loader: LoaderReducer,
  socketR: SocketReducer,
  potion: PotionReducer,
  campaigns: CampaignsReducer,
});
