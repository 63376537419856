import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  male: "",
  female: "",
  uri: "",
};
export const setGenderSlice = createSlice({
  name: "gender",
  initialState,
  reducers: {
    setMale: (state, action) => {
      state.male = action?.payload;
      console.log("setMale", action?.payload);
    },
    setFemale: (state, action) => {
      state.female = action?.payload;
      console.log("setFemale", action?.payload);
    },
    setUri: (state, action) => {
      state.uri = action?.payload;
      console.log("setURI", action?.payload);
    },
  },
});
export const { setMale, setFemale, setUri } = setGenderSlice.actions;

export default setGenderSlice.reducer;
