import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { axiosRequestLocal } from "../../../api/api";
import { useEffect, useState } from "react";
import { CampaignType } from "../../../../types/campaignTypes";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../../Dialog-box/ConfirmDialog";
import { Base_Url } from "../../../api/api";
import { Box } from "@mui/system";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

interface Campaigns {
  campaign: CampaignType;
  deleteOne: any;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ campaign, deleteOne }: Campaigns) {
  const navigate = useNavigate();
  console.log(campaign);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState<CampaignType[]>([]);
  const [typo, settypo] = useState({});
  const [refresh, setReferesh] = useState(false);

  console.log(campaign.collections, "chussing");

  const [anchorEl, setAnchorEnavigatel] = useState(null);
  const open = Boolean(anchorEl);


  const [confirmOpen, setConfirmOpen] = useState(false);

  //delete Campaign
  const deleteCampaign = async () => {
    console.log("i am in delete campaign");
    try {
      const deleteCmpaigns = await axiosRequestLocal(
        "delete",
        // `http://localhost:60000/api/admin/campaign/${campaign.campaign_id}`
        `http://localhost:60000/api/admin/campaign/${campaign.campaign_id}`
      );
      console.log("responseconsoled", deleteCmpaigns);

      setReferesh(!refresh);
      toast.success(
        `campaignId:${deleteCmpaigns.data.deletedCampaign.campaign_id} is successfully deletd `
      );
    } catch (error: any) {
      console.log("error consoled", error);
      if (error.response.status === 404) {
        toast.error(
          `This campaign is not existing`
        );
      } else {
        toast.error(error.response.data.message);
      }
    }
  };
  // console.log(campaign.collections), "chussing");

  // const jsonParser = async()=>{
  //   try {

  //       const jsonObject:JSON = JSON.parse(campaign.typography!);
  //       console.log(jsonObject);

  //   } catch (error) {

  //   }
  // }
  // const getCompaigns = async () => {
  //   try {
  //     const response: any = await axiosRequestLocal(
  //       "get",
  //       `http://localhost:60000/api/admin/campaign`
  //     );
  //     setData(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const navigationToCampaignDetails = ()=>{
    navigate(`/admin-panel/campaign/${campaign.campaign_id}`)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openModal = ()=>
  {
    setConfirmOpen(true);
  }

  useEffect(() => {}, [refresh]);
  // console.log(campaign, "Ithy aa tu");
  return (
    <>
      <Card
        sx={{
          minWidth: 320,
          maxWidth: 320,
          height: 250,
          borderRadius: 4,
          position: "relative",
          overflow: "hidden",
          background: "white",
          border: " solid 1px #e2e8f0 ",
          transition: "transform 0.15s ease-in-out",
          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },

          mb: 6,
        }}
      >
        <Box>
          
            <Box sx={{ display: "flex", position: "relative" }}>
              <CardMedia
                component="img"
                height={250}
                image={Base_Url + `/${campaign.background}`}
                alt="Paella dish"
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "80px" /* Adjust the height as needed */,
                  backgroundColor: "white",
                  opacity: 0.9 /* Adjust the opacity as needed */,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    pl: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      fontSize={19}
                      color="text.secondary"
                    >
                      {/* {campaign.title.length > 10
                        ? campaign.title.substring(0, 10)
                        : campaign.title} */}
                    {campaign.title}
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton  sx={{
                    "&:hover": {
                      color: "yellow",
                    },
                  }}>
                      <EditIcon fontSize={"small"} 
                      onClick={()=>{navigationToCampaignDetails()}}
                      />
                    </IconButton>


                    <IconButton sx={{ "&:hover": { color: "red" } }}>
                      <DeleteIcon fontSize={"small"} 
                      onClick={() =>{openModal()}}
                      />
                    </IconButton>
                  </Box>
                </Box>

                <Box sx={{ pl: 1 }}>
                  <Typography variant="h6" fontSize={12} color="text.secondary">
                    {campaign.description.length > 30
                      ? campaign.description.substring(0, 60)
                      : campaign.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
        </Box>
        {/* <CardContent>
          <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
            <Link
              to={`/admin-panel/campaign/${campaign.campaign_id}`}
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
              state={campaign}
            >
              {" "}
              <MenuItem>
                <IconButton>
                  <EditIcon />
                </IconButton>

                <span style={{ marginLeft: "3px" }}>Edit</span>
              </MenuItem>
            </Link>
            <MenuItem onClick={() => setConfirmOpen(true)}>
              {" "}
              <IconButton aria-label="delete">
                <DeleteIcon />
              </IconButton>{" "}
              <span style={{ marginLeft: "3px" }}>Delete</span>
            </MenuItem>
          </Menu>

          <Box
            sx={{
              display: "flex",
              textAlign: "center",
              flexDirection: "row",
            }}
          >
            <Box sx={{ mb: "6px" }}>
              <Typography variant="h6" color="text.secondary">
                {campaign.title.length > 10
                  ? campaign.title.substring(0, 10)
                  : campaign.title}
              </Typography>
            </Box>
            <Box>
              <CardActions disableSpacing>
             
                <div style={{ marginLeft: "auto" }}>
                  <Badges data={campaign.status} />
                </div>
              </CardActions>
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Link
                to={`/admin-panel/campaign/${campaign.campaign_id}`}
                state={campaign}
              >
                <IconButton
                  sx={{
                    "&:hover": {
                      color: "black",
                    },
                  }}
                >
                  <EditIcon style={{ fontSize: 20 }} />
                </IconButton>
              </Link>
              <IconButton sx={{ "&:hover": { color: "red" } }}>
                <DeleteIcon
                  style={{ fontSize: 20 }}
                  onClick={() => setConfirmOpen(true)}
                />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {campaign.description}
          </Typography>
        </CardContent> */}
      </Card>
      <ToastContainer />
      <div>
        <ConfirmDialog
          title="Delete Campaign?"
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={() => deleteOne(campaign.campaign_id)}
          message="Are you sure you want to delete this Campaign?"
        />
      </div>
    </>
  );
}
