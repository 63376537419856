import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collectionName: "",
  issuer: "",
  taxxon: 0,
  campaignId: 0,
  family: "",
  gasFees: 0,
};
export const setMinterSlice = createSlice({
  name: "mint",
  initialState,
  reducers: {
    setTargetMinter: (state, action) => {
      // console.log("reducermint", action?.payload);
      state.collectionName = action?.payload?.collection_name;
      state.issuer = action?.payload?.issuerId;
      state.gasFees = action?.payload?.transfer_fee_percentage_x_thousand;
      state.taxxon = action?.payload?.taxon;
      state.campaignId = action?.payload?.campaignId;
      state.family = action?.payload?.collection_family;

      // state.male = action?.payload;
      // console.log("setMale", action?.payload);
    },
    // setFemale: (state, action) => {
    //   state.female = action?.payload;
    //   console.log("setFemale", action?.payload);
    // },
  },
});
export const { setTargetMinter } = setMinterSlice.actions;

export default setMinterSlice.reducer;
