import { useRef, useState } from "react";
import ArrowNext from "../../assets/PotionAssets/arrowNext.png";
import ArrowBack from "../../assets/PotionAssets/arrowBack.png";
import NoImage from "../../assets/no-image.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setPotionId } from "../../redux/PotionReducer/PotionReducer";

// Import Swiper styles
import "swiper/css";
import "./PotionCarouselMbl.css";
import { Box, Button, Stack, Typography } from "@mui/material";
import { validNftsI } from "../../types/nftTypes";
import { convertIPFSLink } from "../HelperFunctions/HelperFunctions";

function PotionCarouselMBL() {
  const [activePotion, setActivePotion] = useState<number>(0);
  const dispatch = useDispatch();
  const swiperRef = useRef<any>();
  const bigScreenCheck = useMediaQuery("(max-width:500px)");
  const nfts: validNftsI | undefined | null = useSelector(
    (state: any) => state.nfts?.nftObj
  );
  const setPotionIdFunc = (id: any) => {
    dispatch(setPotionId(id));
    setActivePotion(id);
  };

  if (!nfts) return noPotionComponent;

  let slidesPerViewValue = 4;
  if (!bigScreenCheck) slidesPerViewValue = 5;

  const isOnlyOnePotion = nfts?.potionNfts?.length === 1;
  if (isOnlyOnePotion) slidesPerViewValue = 1;
  if (nfts?.potionNfts?.length === 2) slidesPerViewValue = 2;
  if (nfts?.potionNfts?.length === 3) slidesPerViewValue = 3;

  return (
    <>
      <Stack direction="row">
        <Button onClick={() => swiperRef.current?.slidePrev()}>
          <img src={`${ArrowBack}`} alt="" style={{ width: 10 }} />
        </Button>
        {nfts?.potionNfts ? (
          <Swiper
            spaceBetween={1}
            slidesPerView={nfts?.potionNfts?.length === 1 ? 1 : nfts?.potionNfts?.length === 2 ? 2: nfts?.potionNfts?.length === 3? 3 : 4}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {nfts?.potionNfts?.map((potion: any, index: number) =>
              bigScreenCheck ? (
                activePotion == potion?.NFTokenID ? (
                  <>
                    <Box sx={{ p: 3, mb: 1 }}>
                      <SwiperSlide
                        style={{
                          paddingBlock: 2,
                          borderRadius: 6,
                        }}
                        onClick={() => setPotionIdFunc(potion.NFTokenID)}
                      >
                        {potion.content?.image && !potion.content?.video ? (
                          <img
                            src={convertIPFSLink(potion?.content?.image)}
                            alt={potion?.content?.name}
                            crossOrigin="anonymous"
                            style={{ width: 40, border: "4px solid #0000FF" }}
                            key={potion?.NFTokenID}
                          />
                        ) : potion.content?.video && !potion.content?.image ? (
                          <video
                            loop
                            autoPlay // Add autoPlay to make it autoplay
                            muted
                            crossOrigin="anonymous"
                            style={{
                              width: 95,
                              borderRadius: "6px",
                              border: "4px solid #0000FF",
                            }}
                            key={potion?.NFTokenID}
                          >
                            <source
                              src={convertIPFSLink(potion?.content?.video)}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                                  src={NoImage}
                                  alt={""}
                                  crossOrigin="anonymous"
                                  style={{
                                    width: 55,
                                    borderRadius: 6,
                                    border: "4px solid #0000FF",
                                  }}
                                  key={potion?.NFTokenID}
                                />
                        )}
                      </SwiperSlide>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ p: 3, mb: 1 }}>
                    <SwiperSlide
                      style={{
                        paddingBlock: 2,
                        borderRadius: 6,
                      }}
                      onClick={() => setPotionIdFunc(potion.NFTokenID)}
                    >
                      {potion.content?.image && !potion.content?.video ? (
                        <img
                          src={convertIPFSLink(potion?.content?.image)}
                          alt={potion?.content?.name}
                          crossOrigin="anonymous"
                          style={{ width: 40 }}
                          key={potion?.NFTokenID}
                        />
                      ) : potion.content?.video && !potion.content?.image ? (
                        <video
                          loop
                          autoPlay // Add autoPlay to make it autoplay
                          muted
                          crossOrigin="anonymous"
                          key={potion?.NFTokenID}
                          style={{ width: 95, borderRadius: "6px" }}
                        >
                          <source
                            src={convertIPFSLink(potion?.content?.video)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                                  src={NoImage}
                                  alt={""}
                                  crossOrigin="anonymous"
                                  style={{
                                    width: 55,
                                    borderRadius: 6,
                                  }}
                                  key={potion?.NFTokenID}
                                />
                      )}
                    </SwiperSlide>
                  </Box>
                )
              ) : (
                <Swiper
                  spaceBetween={12}
                  slidesPerView={slidesPerViewValue}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  style={{ paddingInline: 4, paddingBottom: 4 }}
                >
                  <Box
                    sx={{ p: 3, mb: 1 }}
                    onClick={() => setPotionIdFunc(potion.NFTokenID)}
                  >
                    <>
                      <SwiperSlide style={{ paddingBlock: 2 }}>
                        {potion.content?.image && !potion.content?.video ? (
                          <img
                            src={convertIPFSLink(potion?.content?.image)}
                            alt={potion?.content?.name}
                            crossOrigin="anonymous"
                            style={{ width: 40 }}
                            key={potion?.NFTokenID}
                          />
                        ) : potion.content?.video && !potion.content?.image ? (
                          <video
                            loop
                            autoPlay // Add autoPlay to make it autoplay
                            muted
                            style={{ width: 55, borderRadius: "6px" }}
                          >
                            <source
                              src={convertIPFSLink(potion?.content?.video)}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                                  src={NoImage}
                                  alt={""}
                                  crossOrigin="anonymous"
                                  style={{
                                    width: 55,
                                    borderRadius: 6,
                                  }}
                                  key={potion?.NFTokenID}
                                />
                        )}
                      </SwiperSlide>{" "}
                    </>
                  </Box>
                </Swiper>
              )
            )}
          </Swiper>
        ) : (
          noPotionComponent
        )}
        <Button onClick={() => swiperRef.current?.slideNext()} size="small">
          <img src={`${ArrowNext}`} alt="" style={{ width: 10 }} />
        </Button>
      </Stack>
    </>
  );
}

export default PotionCarouselMBL;

const noPotionComponent = (
  <Box sx={{ display: "flex", justifyContent: "center" }}>
    <Typography sx={{ color: "black" }}>{"No potion to burn"}</Typography>
  </Box>
);
