import React from "react";
import { Paper, Box, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../../assets/BreedingAssets/logo.png";

import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/LoginReducers/LoginReducers";
import { setMale,setFemale } from "../../../redux/GenderReducer/GenderReducer";
import { setNFT } from "../../../redux/NFTReducer/NFTReducer";
import { setPotionId } from "../../../redux/PotionReducer/PotionReducer";
import { connectSocket } from "../../../redux/SocketReducer/SocketReducer";
import { setApiHit } from "../../../redux/LoaderReducer/LoaderReducer";
import { setCampaigns } from "../../../redux/CampaignsReducer/CampaignsReducer";
import { setLoader } from "../../../redux/QueueProcess/QueueProcessReducer";
import { XummPkce, ResolvedFlow } from "xumm-oauth2-pkce";



export default function Appbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const XUMM_API_KEY: any = process.env.REACT_APP_XUMM_API_KEY;

  const xumm2 = new XummPkce(XUMM_API_KEY);
  console.log(currentRouteName, "aaa");  
  const handleUsedNftsButton = () => {
    navigate("/admin-panel/usedNfts");
  };

  const handleAdminMangement =()=>
  {
    navigate("/admin-panel/admins-management");
  }

  const handleGoToUserPanel = () => {
    navigate("/campaigns");
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    xumm2.logout();
      
    localStorage.clear();
    navigate("/");
    dispatch(logout());
    dispatch(setCampaigns(null));
    dispatch(setApiHit(false));
    dispatch(setMale(""));
    dispatch(setPotionId(""));
    dispatch(setFemale(""));
    dispatch(setNFT({}));
  
    dispatch(connectSocket({}));
    dispatch(setLoader(false));
  };

  const handleButton = () => {
    // if (currentRouteName === "/admin-panel")
    //   navigate("/admin-panel/create-campaign");
    // else {
    navigate("/admin-panel");

    // }
  };
  //   create - campaign;


  return (
    <Paper className="AdminPanelNavbar" elevation={2} sx={{ borderRadius: 0 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleButton}
          >
            <img
              src={`${Logo}`}
              alt=""
        
              width="60%"
              style={{ paddingTop: "5px",cursor:"pointer" }}
            />
            {/* < */}
          </Box>
          <Box sx={{ ml: "10px", mt: "5px" }}>
            <Button
              size="small"
              onClick={handleUsedNftsButton}
              variant="outlined"
              color="primary"
            >

              Used NFTS
            </Button>

           
          </Box>
          <Box sx={{ ml: "10px", mt: "5px" }}>
            <Button
              size="small"
              onClick={handleAdminMangement}
              variant="outlined"
              color="primary"
            >
              Admins
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "flex", direction: "row" }}>
        <Box sx={{ ml: "10px", mt: "5px", mr:"10px" }}>
            <Button
              size="small"
              onClick={handleGoToUserPanel}
              variant="outlined"
              color="primary"
            >
              Go to User Panel
            </Button>
          </Box>
          <Box sx={{ mt: "5px", mr: 5 }}>
            <Button size="small" onClick={handleLogout} variant="outlined">
              Logout
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
