import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Card, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router";

import BackgroundImage from "../../assets/Background/backgroundImage.png";
import Logo from "../../assets/BreedingAssets/logo.png";

import { useSelector } from "react-redux";
import LoginComp from "../../Components/Login/Login";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logged from "../../assets/HomeAssets/Logged.mp4";
import { Base_Url, axiosRequestLocal } from "../../Components/api/api";

const Home = () => {
  // ----------------States------------------------
  const navigate = useNavigate();
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");
  const loged = useSelector((state: any) => state.xumm?.account);
  let locakToken = localStorage.getItem("token");

  // --------------useEffects-------------------
  useEffect(() => {
    if (loged && Object.keys(loged).length) {
     
      const timer = setTimeout(() => {
        navigate("/campaigns");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [loged, locakToken]);



  return (
    <>
      {mobileViewCheck ? (
        <>
          {loged && Object.keys(loged).length ? (
            <div style={{ position: "relative" }}>
              <video width="100%" src={`${Logged}`} autoPlay muted></video>
            </div>
          ) : (
            <div>
              <img
                src={`${BackgroundImage}`}
                alt=""
                style={{ width: "100%", height: "100vh" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
              >
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          paddingTop: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box />
                        <img src={`${Logo}`} alt="" width="200vw" />
                        <Box />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        p: 0,
                      }}
                    >
                      <Box
                        sx={{
                          // width: "100%",
                          height: "40vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        // position="relative"
                      >
                        <Box />
                        <Box>
                          <Typography
                            sx={{
                              color: "white",
                              fontFamily: "jedi",
                              fontSize: 30,
                              fontWeight: 30,
                            }}
                          >
                            LOG IN TO YOUR XUMM WALLET
                          </Typography>
                        </Box>

                        <Box />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        p: 0,
                      }}
                    >
                      <Box
                        sx={{
                          height: "30vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box />
                        <LoginComp />
                        <Box />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>
          <img
            src={`${BackgroundImage}`}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      paddingTop: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box />
                    <img src={`${Logo}`} alt="" width="200vw" />
                    <Box />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    p: 0,
                  }}
                >
                  <Box
                    sx={{
                      // width: "100%",
                      height: "50vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    // position="relative"
                  >
                    <Box />
                    <Box sx={{display:"flex",justifyContent:"center"}}>
                      <Typography
                        sx={{
                          color: "white",
                          fontFamily: "jedi",
                          display:"flex",
                          justifyContent:"center",
                          fontSize: 25,
                          fontWeight: 600,
                          ml: "50px",
                        }}
                      >
                        {"LOG IN TO YOUR XUMM WALLET"}
                      </Typography>
                    </Box>

                    <Box />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    p: 0,
                  }}
                >
                  <Box
                    sx={{
                      // width: "100%",
                      height: "50vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    position="relative"
                  >
                    <Box />
                    <LoginComp />
                    <Box />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
