import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  FormControl,
  FormHelperText,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  SelectChangeEvent,
  Paper,
  Modal,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldProps,
  Formik,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";
import { axiosRequestHeaders, axiosRequestLocal } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_Url } from "../../../api/api";
import { CampaignType } from "../../../../types/campaignTypes";
import { Traits } from "../../../../types/traitsType";
import "../Form/MyForm.css";
import { TraitContentsUpdateType } from "../../../../types/TraitContentUpdateType";
import DynamicTableView from "../../Traits Components/DynamicTableView/DynamicTableView";
import { Link, useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  backgroundColor: "white",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Column {
  id: "id" | "imageName"  | "createdAt" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}

const columns: Column[] = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "imageName", label: "Image", minWidth: 100 },
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 100 },
];

interface campaigns {
  campaign: CampaignType;
  setRefresh: Function;
  refresh: boolean;
}
interface TraitContentData {
  id: any;
  imageName: string;
}
const editValidationSchema = Yup.object().shape({
  imageName: Yup.mixed()
    .required("Image is required")
    .test(
      "fileType",
      "Only image files are allowed",
      (value) =>
        value && value instanceof File && value.type.startsWith("image/")
    )
    .test(
      "fileSize",
      "File size should be less than or equal to 20MB",
      (value) => value && value instanceof File && value.size <= 20 * 1024 * 1024
    ),
});


interface FormDataInterface {
  trait_id: string;
  // order: number;
  // imageName: string;
  traitImages: string[];
}
const initialValues: FormDataInterface = {
  trait_id: "",
  // order: 0,
  // imageName: "",
  traitImages: [],
};

const TraitContentsUpdate = ({ campaign,setRefresh, refresh }: campaigns) => {
  // get data from campagin, Campagin ID
  const navigate = useNavigate();
  const campaignid = campaign.campaign_id.toString();
  // const [allCampaigns, setCampaigns] = useState<CampaignType[]>([]);
  // const [campaignid, setCampaignId] = useState("");
  // get data from trait, trait ID
  const [traits, setTraits] = useState<Traits[]>([]);
  const [traitId, setTraitId] = useState("");
  const [traitContentsByTraitId, setTraitContentbyTraitId] = useState<
    TraitContentsUpdateType[]
  >([]);

  // loading
  const [loading, setLoading] = useState(false);
  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion

  // order and multiple Images
  const [order, setOrder] = useState(0);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  //  Table View States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [preview, setPreview] = useState("");
  const [bgimg, setImg] = useState<Boolean>(false);

  // modal useState
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] =
    useState<TraitContentData | null>(null);

  const [selectedImageInModal, setSelectedImageInModal] = useState<File | null>(
    null
  );
 

  // const getAllCampaigns = async () => {
  //   console.log("Working to get campaign Id");

  //   try {
  //     const response: any = await axiosRequestLocal(
  //       "get",
  //       Base_Url + `/api/admin/campaign`,
  //       {},
  //       {}
  //     );
  //     if (response.data && response.data.campaigns) {
  //       setCampaigns(response.data.campaigns);
  //     }
  //   } catch (error) {
  //     console.log("Error fetching data:", error);
  //   }
  // };
  // get Traits according to Campaign Id
  const getTraitsfromCampaignId = async (campaignid: string) => {
    console.log("Working to get campaign Id");

    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${campaignid}`,
        {},
        {}
      );
      if (response.data) {
        const traitData = await response.data.campaign.traits;
        setTraits(traitData);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  // get TraitContent by Trait id
  const getTraitsfromTraitId = async (_traitId: string) => {
    console.log("Working to get campaign Id");
    console.log(_traitId);
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/trait/${_traitId}`,
        {},
        {}
      );

      const traitData = await response.data.triatcontent.data.traitContent;
      setTraitContentbyTraitId(traitData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Handle Dynamic Table View
  // const handleChangeOfCampaignId = (event: SelectChangeEvent<string>) => {
  //   setCampaignId(event.target.value);
  // };
  const handleChangeOfTraitId = (event: SelectChangeEvent<string>) => {
    setTraitId(event.target.value);
  };

  // Handle Add the Trait Content
  // const handleAddTraitContents = async () => {
  //   if ( selectedImages.length === 0 || !traitId) {
  //     // Validate the inputs, show error message if needed
  //     return;
  //   }

  //   const selectedTrait = traits.find((trait) => trait.id === Number(traitId));
  //   const traitTitle = selectedTrait?.title || ""; // Get the selected trait's title

  //   const formData = new FormData();
  //   formData.append("trait_id", traitId);
  //   formData.append("order", String(order));
  //   formData.append("imageName", traitTitle); // Use the trait's title as the imageName

  //   selectedImages.forEach((file) => {
  //     formData.append("traitImages", file);
  //   });
  //   setLoading(true);
  //   try {
  //     await schema.validate({ imageName: selectedImages[0] });
  //     const response = await axiosRequestHeaders(
  //       "post",
  //       Base_Url + `/api/admin/trait/traitcontent`,
  //       formData,
  //       undefined
  //     );

  //     // Handle successful response
  //     console.log(response, "Response from backend");
  //     setLoading(false);

  //     toast.success("Trait Content Successfully Added", { autoClose: 3000 });
  //     getTraitsfromTraitId(traitId);
  //     // Clear input fields and state
  //     const input = document.getElementById("traitImages") as HTMLInputElement;
  //     if (input) {
  //       input.value = "";
  //     }
  //     setOrder(0);
  //     setSelectedImages([]);
  //     setTraitId("");
  //   } catch (error: any) {
  //     setLoading(false);

  //     console.error("An error occurred:", error);
  //     toast.error(error.response.data.message);
  //   }
  // };

  // const handleAddImages = () => {
  //   if (order < 0 || selectedImages.length === 0 || !traitId) {
  //     // Validate the inputs, show error message if needed
  //     return;
  //   }

  //   // Create new rows for each selected image
  //   const newRows = selectedImages.map((file, index) => ({
  //     id: traitContentsByTraitId.length + index + 1,
  //     order: order,
  //     imageName: file.name,
  //     trait_id: Number(traitId), // Use the traitId directly
  //     createdAt: new Date().toISOString(),
  //     updatedAt: new Date().toISOString(),
  //   }));

  //   // Add the new rows to the state
  //   setTraitContentbyTraitId([...traitContentsByTraitId, ...newRows]);

  //   // Reset the input fields
  //   setOrder(0);
  //   setSelectedImages([]);
  //   setTraitId(""); // Reset the traitId as well
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDelete = async (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true); // open the confirmation dialog
  };
  // delete the traitContent image
  const deleteTraitContent = async (id: number) => {
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/admin/trait/traitcontent/delete/${id}`,
        {},
        {}
      );
      toast.success("Trait Image Successfully Deleted", { autoClose: 3000 });
      // setTimeout(()=>
      // {
        setLoading(false);
      // },2000)
      getTraitsfromTraitId(traitId);
    } catch (error: any) {
      console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };

  const handleEdit = (id: number) => {
    // Find the collection data with the given id
    const traitContentToEdit = traitContentsByTraitId.find(
      (traitContentsByTraitId) => traitContentsByTraitId.id === id
    );

    if (traitContentToEdit) {
      // Create a new object of type TraitContentData with the necessary properties
      const traitContentsData: TraitContentData = {
        id: traitContentToEdit.id || 0, // Use 0 or another default value if 'id' is undefined
        imageName: traitContentToEdit.imageName,
      };

      // Set the selected row data and open the edit modal
      setSelectedRowData(traitContentsData);
      setEditModalOpen(true);

      // Set the selected image for editing
      setSelectedImageInModal(null); // Clear any previous selection

      // Set the preview URL for displaying the image
      const imageUrl = Base_Url + `/${traitContentsData.imageName}`;
      setPreview(imageUrl);
    }
  };
  // Define the handleEditSubmit function to handle form submission in the edit modal
  const handleEditSubmit = async (values: TraitContentData) => {
    try {
      // Make an API call to update the collection item using values
      // After successfully updating, close the modal and reset edit state
      await updateTraitContents(values); // Replace with your update API call
      setEditModalOpen(false);
      setSelectedRowData(null);
      // Optionally, refresh the collection data to reflect changes
      getTraitsfromTraitId(traitId);
      toast.success("Trait Successfully Edited");
    } catch (error: any) {
      // Handle any errors here
      console.error("Error updating item:", error);
      toast.error(error.response.data.message);
    }
  };

  const updateTraitContents = (values: TraitContentData) => {
    console.log("Update Function is called");
  };
  const supportedImageTypes = ["image/jpeg", "image/png", "image/gif"];

interface FileWithTypeInfo extends File {
  type: string;
}

const isImageFile = (file: FileWithTypeInfo) =>
  supportedImageTypes.includes(file.type);

  const validationSchema = Yup.object().shape({
    // trait_id: Yup.string().required("Trait is required"),
    // order: Yup.number()
    // .required("Order number is required")
    // .min(1, "Order number must be greater than 0"),
    // imageName: Yup.string().required("Image Name is required"),
    traitImages: Yup.array()
      .min(1, "Please select at least one file")
      .test("fileType", "Only image files are allowed", function (value) {
        if (!value) {
          return true; // Handle empty array case or you can use `.min` above to enforce minimum items.
        }
        return value.every((file) => isImageFile(file));
      }),
  });

  const handleFormSubmit = async (
    values: FormDataInterface,
    actions: FormikHelpers<FormDataInterface>
  ) => {
    console.log("What are values",values);
    const formData = new FormData();
    // @ts-ignore
    formData.append("trait_id", traitId);
    formData.append("campaignId", campaignid);
    // @ts-ignore

    values.traitImages.forEach((file) => {
      formData.append("traitImages", file);
    });

  
    console.log(formData);
    setLoading(true);
    try {
      const response: any = await axiosRequestHeaders(
        "post",
        `${Base_Url}/api/admin/trait/traitcontent`,
        formData,
        undefined
      );
      console.log(response, "Noman pc response");
      const input = document.getElementById("traitImages") as HTMLInputElement;
      if (input) {
        input.value = "";
      }
      actions.resetForm();
      setSelectedImages([]);
      toast.success("Trait Content Successfully Added", { autoClose: 3000 });
      // setTimeout(()=>
      // {
        setLoading(false);
      // },2000);
      getTraitsfromTraitId(traitId);

    } catch (error: any) {
      console.error("An error occurred:", error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };


  const editInitialValues: TraitContentData = {
    id: selectedRowData?.id || undefined,
    imageName: selectedRowData?.imageName || "",
  };

  // Use Effects
  // useEffect(() => {
  //   getAllCampaigns();
  // }, [refresh]);

  useEffect(() => {
    if (campaignid !== "") {
      getTraitsfromCampaignId(campaignid);
    }
  }, [campaignid,refresh]);

  useEffect(() => {
    if (traitId !== "") {
      setLoading(true);
      getTraitsfromTraitId(traitId);
      setLoading(false);
    }
  }, [traitId]);
  const handleButton = () => {
    navigate(`/preview/${campaign.campaign_id}`);
  };

  console.log("traitContentsByTraitId", traitContentsByTraitId);
  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h5" sx={{fontWeight:600 }}>Trait Images</Typography>
          <Grid container spacing={2} sx={{ mt: "2px" }}>
            {/*---------------- Select Trait--------------- */}

            <Grid item lg={6} sm={12} xs={12}>
              <Typography sx={{ fontWeight: "bold", color: "#555555",fontSize:"14px" }}>
                Select Traits
              </Typography>
              <FormControl style={{ minWidth: 120 }} size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="trait_id"
                  defaultValue=""
                  sx={{ width: "250px" }}
                  displayEmpty
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChangeOfTraitId}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Traits</em>;
                    }
                    const selectedTraits = traits?.find(
                      (traits) => traits?.id === Number(selected)
                    );
                    return selectedTraits ? (
                      selectedTraits?.title
                    ) : (
                      <Typography>Traits</Typography>
                    );
                  }}
                >
                  {traits?.map((trait) => (
                    <MenuItem key={trait?.id} value={trait?.id}>
                      {trait?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Typography mt={"20px"}>List of Trait Images </Typography>
          <Box>
            <Box sx={{ marginTop: "10px" }}>
              {loading ? (
                <Box display="flex" justifyContent="center" mt={3}>
                  <CircularProgress size={100} sx={{ color: "black" }} />
                </Box>
              ) : (
                <DynamicTableView
                  columns={columns}
                  data={traitContentsByTraitId}
                  loading={loading}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleDelete={handleDelete}
                  showEditIcon={false}
                  handleEdit={handleEdit}
                  confirmOpen={confirmOpen}
                  setConfirmOpen={setConfirmOpen}
                  selectedItemId={selectedItemId}
                  deleteFunction={deleteTraitContent}
                />
              )}
            </Box>
            <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {" "}
                <Grid container>
                  <Grid container spacing={2} sx={{ mt: 2 }}>

            
                    {/*---------------- Order--------------- */}

                    {/* <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Order
                    </Typography>
                    <div>
                      <TextField
                        placeholder="order"
                        id="outlined-size-small"
                        type="text"
                        size="small"
                        sx={{ marginTop: "2px", width: "250px" }}
                        onBlur={handleBlur}
                        defaultValue={"order"}
                        onChange={handleChange}
                        value={values.order}
                        name="order"
                        error={!!touched.order && !!errors.order}
                        helperText={touched.order && !!errors.order}
                      ></TextField>
                    </div>
                  </Grid> */}
                    {/*---------------- Image Name--------------- */}

                    {/* <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Image Name
                    </Typography>
                    <div>
                      <TextField
                        placeholder="image name"
                        id="traits-images"
                        size="small"
                        defaultValue="name"
                        sx={{ marginTop: "2px", width: "250px" }}
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.imageName}
                        name="imageName"
                        error={!!touched.imageName && !!errors.imageName}
                        helperText={touched.imageName && !!errors.imageName}
                      ></TextField>
                    </div>
                  </Grid> */}
                    {/*---------------- Upload Image--------------- */}
                    <Grid item lg={6} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#555555",
                          fontSize: 12,
                        }}
                      >
                        Choose Multiple Trait Images
                      </Typography>
                      <input
                        type="file"
                        multiple
                        id="traitImages"
                        name="traitImages"
                        className="custom-file-input-traits"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const files = event.currentTarget.files;
                          if (files) {
                            const fileArray = Array.from(files);
                            setFieldValue("traitImages", fileArray);
                          }
                        }}
                      />
                      <ErrorMessage name="traitImages" component="div" className="error-message" />
                    </Grid>
                    <Grid
                item
                lg={6}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    backgroundColor: "#6EC8BB",
                    "&:hover": {
                      backgroundColor: "skyBlue",
                    color: "white",

                    },
                  }}
                  onClick={handleButton}
                >
                  {"Layers Preview >>"}
                </Button>
              </Grid>
                  </Grid>
                  {/*---------------- Button--------------- */}
                  <Box display="flex" justifyContent="start" mt="40px">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#454545 ",
                        width: "100px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                       {loading === false ? (
                      "Update"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                    </Button>
                  </Box>
                </Grid>
              </form>
            )}
          </Formik>
          </Box>
        </Box>
        {isEditModalOpen && (
          <Modal
            open={isEditModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedRowData(null);
              setSelectedImageInModal(null); // Clear selected image when closing the modal
            }}
          >
            <Paper elevation={4} sx={{ ...style, width: 250, borderRadius: 4 }}>
              <Typography variant="h5">Edit Trait Image</Typography>

              <Formik
                initialValues={editInitialValues}
                onSubmit={handleEditSubmit}
                validationSchema={editValidationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* Display the selected image */}
                    {preview && ( // Use the preview state here
                      <div>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Selected Image:
                        </Typography>
                        <img
                          src={preview} // Use the preview state as the image source
                          alt="Selected Image"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    )}

                    {/* File input for updating the image */}
                    <FormControl fullWidth>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const file = event.currentTarget.files?.[0];
                          if (file) {
                            setSelectedImageInModal(file); // Update the selected image
                            setFieldValue("imageName", file);
                            setPreview(URL.createObjectURL(file)); // Update the preview state with the selected image
                          }
                        }}
                      />
                      {touched.imageName && !!errors.imageName && (
                        <FormHelperText>{errors.imageName}</FormHelperText>
                      )}
                    </FormControl>

                    {/* Update and Close buttons */}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: "10px" }}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        setEditModalOpen(false);
                      }}
                      variant="contained"
                      color="error"
                      sx={{ marginTop: "10px", marginLeft: "5px" }}
                    >
                      Close
                    </Button>
                  </form>
                )}
              </Formik>
            </Paper>
          </Modal>
        )}
        <ToastContainer />
      </Paper>
    </Container>
  );
};

export default TraitContentsUpdate;
