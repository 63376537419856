import React, { useState } from "react";
import AdminPanel from "./AdminPanel";
import UsedNftsComp from "../../Components/Admin-Scenes/UsedNFTsComp/UsedNftsComp";

const UsedNfts = () => {
  return (
    <AdminPanel>
      <UsedNftsComp />
    </AdminPanel>
  );
};

export default UsedNfts;
