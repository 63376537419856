import { Box, Button, Grid, Typography } from "@mui/material";
import { width } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { CampaignType } from "../../../../types/campaignTypes";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

interface headerAndBreedButtonPropsI {
  handleOpenModal: () => void;
  isScreenLessThan1725px: boolean;
  campaign: CampaignType;
}

function HeaderAndBreedButton({
  handleOpenModal,
  campaign,
  isScreenLessThan1725px,
  
}: headerAndBreedButtonPropsI) {
  const navigate = useNavigate();
  const potionId = useSelector((state: any) => state.potion?.potionId);
  const maleToken = useSelector((state: any) => state.gender?.male);
  const femaleToken = useSelector((state: any) => state.gender?.female);


  console.log("Potion ID on HeaderAnd Breed Comp", potionId);

  const handleButtonClick = () => {
    if (campaign) {
      const { campaignType, campaign_id } = campaign;
     
  if (campaignType === '1') {
    if (maleToken && femaleToken && potionId) {
      navigate(`/characterBuilding/${campaign_id}`);
    }else if(!maleToken && femaleToken && potionId)
    {
      toast.error("Please select Group A");
    }
    else if(!femaleToken && maleToken &&  potionId)
    {
      toast.error("Please select Group B");
    } 
    else if(femaleToken && maleToken && !potionId)
    {
      toast.error("Please select a Potion");
    }
    else {
      toast.error("Please select all required tokens.");
    }
  } else if (campaignType === '2') {
    if (maleToken && potionId) {
      navigate(`/characterBuilding/${campaign_id}`);
    }
    else if(!maleToken && potionId)
    {
      toast.error("Please select Group A");
    } 
    else if(maleToken && !potionId)
      {
        toast.error("Please select a Potion");

      }
    else {
      toast.error("Please select all required tokens.");
    }
  } else if (campaignType === '3') {
    if (potionId) {
      navigate(`/characterBuilding/${campaign_id}`);
    } else {
      toast.error("Please select a potion.");
    }
  } else {
    toast.error("Invalid campaign type.");
  }
} else {
  toast.error("Campaign data is not available.");
}
    
  };

  return (
    <Grid item sm={6}>
      <Box
        width="100%"
        borderRadius="8px"
        sx={{
          border: `1px solid ${campaign?.buttonColor !=null ? campaign?.buttonColor :"#6DF8FE" }`,
          mt: 2,
          p: 2,
          mb: isScreenLessThan1725px ? 4 : 6,
          background: "#1b1b1b35",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontFamily: "Raleway",
            fontSize: "25px",
            // fontWeight:"bold",
            color: "white",
            textAlign:"center"
          }}
        >
          {campaign?.description}
        </Typography>
      </Box>

      {/* <Box
        justifyContent="center"
        // width="80%"
        borderRadius="8px"
        sx={{
          paddingInline: 2,
          p: 1,
          marginInline: 10,
          border: "1px solid #6DF8FE",
          background: "rgba(70, 70, 70, 0.8);",
          justifyContent: "center",
          // ml: isScreenLessThan1725px ? 7 : 0,
          color: "white",
          zIndex: 99,
          position: "relative",
          mt: isScreenLessThan1725px ? 0 : 4,
        }}
      >
        {/* <img src={`${Header2}`} alt="" width="40%" /> {" :"} */}
      {/* </Box>  */}

      <Box
      sx={{
    display: "flex",
    justifyContent: isScreenLessThan1725px ? "center" : "flex-start",
    mt: isScreenLessThan1725px ? 2 : 2,
    ml: campaign.campaignType === '2'
      ? (isScreenLessThan1725px ? "70px" : "15%")
      : "70px",
  }}
>
        <Button
          onClick={handleButtonClick}
          size={isScreenLessThan1725px ? "small" : "large"}
          sx={{
            opacity: 1,
            color: "white",
            backgroundColor: "#1b1b1b35", //here I am putting background
            mr: isScreenLessThan1725px ? 8 : 0,
            p: isScreenLessThan1725px ? 1 : 3,
            paddingInline: isScreenLessThan1725px ? 2.5 : 8,
            border: `1px solid ${campaign?.buttonColor !=null ? campaign?.buttonColor :"#6DF8FE" }`,
            "&:hover": {
              background: campaign?.buttonColor !=null ? campaign?.buttonColor :"rgba(109, 228, 264, 0.9)",
              color: "white",
            },
            fontSize: isScreenLessThan1725px ? 18 : 28, //here font Size will be putted
            // fontFamily:{}   //here font family will be putted
            fontWeight: 600,
            ml: isScreenLessThan1725px ? "1%" : "38.5%",
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Create</Typography>
        </Button>
      </Box>
      <ToastContainer />
    </Grid>
  );
}

export default HeaderAndBreedButton;
