import React from "react";
import AdminPanel from "./AdminPanel";
const WelcomePage = () => {
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "50px" }}
      >
        <div>
          <h5 style={{ fontSize: "20px" }}>Did not Found</h5>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
