import React from 'react'
import AdminPanel from './AdminPanel'
import AdminManagementComp from '../../Components/Admin-Scenes/AdminManagementComp/AdminManagementComp'

const AdminManagement = () => {
  return (
    <AdminPanel>
      <AdminManagementComp />
    </AdminPanel>
  )
}

export default AdminManagement
