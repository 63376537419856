import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import DynamicTableView from "../Traits Components/DynamicTableView/DynamicTableView";
import { ToastContainer, toast } from "react-toastify";
import { Base_Url, axiosRequestLocal } from "../../api/api";
import { convertIPFSLink } from "../../HelperFunctions/HelperFunctions";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useNavigate } from "react-router-dom";

interface Column {
  id: "id" | "userAccount" | "parent" | "child" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string | JSX.Element;
}
const columns: Column[] = [
  { id: "id", label: "Id", minWidth: 100 },
  { id: "userAccount", label: "UserAccount", minWidth: 100 },
  { id: "parent", label: "Parent", minWidth: 100 },
  { id: "child", label: "TxnHash", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const UsedNftsComp = () => {
  // use States
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const accountAddress = localStorage.getItem("accnum");


  //  Table View States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Confirm dialogue states
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0); // Add this state to track the selected item's ID for deletion

  const dummyData = [
    {
      id: 100,
      userAccount: "rnB8jr6kZ3HZqcNeTYpvV3HnjUeHKxTLgK",
    },
  ];

  // UsefNfts
  const [usedNftsByUser, setUsedNftsByUser] = useState<any[]>(dummyData);

  //------------------- Functions ----------------------
  const getNFtsMinted = async () => {
    console.log("Working to getting NFTS minted");
    setLoading(true);
    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/api/luxlion/nfts-minted`,
        {},
        {}
      );
      console.log("Data", response.data);
      if (response.data) {
        const nftsMinted = await response.data.nfts;
        setUsedNftsByUser(nftsMinted);
        console.log("NFT minted", nftsMinted);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Table View
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //   Delete Prop
  const handleDelete = (id: number) => {
    setSelectedItemId(id);
    setConfirmOpen(true);
  };

  const handleEdit = (id: number) => {
    // Find the collection data with the given id
    console.log("No edit here sorry");
  };

  // delete the traitContent image
  const deleteUsedNfts = async (id: number) => {
    try {
      const response: any = await axiosRequestLocal(
        "post",
        Base_Url + `/api/luxlion/nft-delete/${id}`,
        {},
        {}
      );
      toast.success("Minted Nfts Successfully Deleted", { autoClose: 3000 });
      getNFtsMinted();
    } catch (error: any) {
      console.log("Error fetching data:", error);
      toast.error(error.response.data.message);
    }
  };
  const checkAdmin = async (accountAddress:string): Promise<void> => {
    try {
      console.log('LOGGED --->',accountAddress)
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        console.log(URL);
        const response: any = await axiosRequestLocal("get", URL);
        console.log("Response of IsAdmin", response.data.status);
        if(response.data && response.data.status){
          navigate(`/admin-panel/usedNfts`);
        }else{
          console.log('--------------->')
          navigate('/');
        }
      }else{
        navigate('/');
      }
    } catch (error) {
      console.log("Response of IsAdmin", error);
      navigate('/');
      console.log(error);
    }
  };


  // ------------------------------Use Effects---------------------------------

  useEffect(() => {
    // document.body.style.overflow = "auto";
    if(accountAddress){

      checkAdmin(accountAddress);
    }
    // setTimeout(() => {
      setLoading(false);
    // }, 2000);
    getNFtsMinted();
    setLoading(true);
  }, []);

  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h3">Used NFTS</Typography>
          <Grid container spacing={2} sx={{ mt: "2px" }}>
            {/*---------------- Select Trait--------------- */}
          </Grid>

          <Typography mt={"20px"}>List of Used Nfts </Typography>
          <Box>
            <Box sx={{ marginTop: "10px" }}>
              {loading ? (
                <Box display="flex" justifyContent="center" mt={3}>
                  <CircularProgress size={70} sx={{ color: "black" }} />
                </Box>
              ) : (
                <DynamicTableView
                  columns={columns}
                  data={usedNftsByUser}
                  loading={loading}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleDelete={handleDelete}
                  showEditIcon={false}
                  handleEdit={handleEdit}
                  confirmOpen={confirmOpen}
                  setConfirmOpen={setConfirmOpen}
                  selectedItemId={selectedItemId}
                  deleteFunction={deleteUsedNfts}
                />
              )}
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Paper>
    </Container>
  );
};

export default UsedNftsComp;
