import React, { useState, useEffect } from "react";
import {
  MintingObj,
  breedRequestBody,
  rightBreedRequestBody,
} from "../Character Design Comp/DesignGrids";
import { APIObject } from "../Character Design Comp/DesignGrids";
import { Box, Paper, Button } from "@mui/material";
import { Stage, Layer, Image } from "react-konva";
import { useDispatch } from "react-redux";
import { Base_Url } from "../../api/api";
import { setUri } from "../../../redux/GenderReducer/GenderReducer";

interface CanvasPropsI {
  selectedTraitsArray: string[];
  setMintingObjectName: MintingObj;
}

const Canvas = ({
  selectedTraitsArray,
  setMintingObjectName,
}: CanvasPropsI) => {
  console.log("canvas", selectedTraitsArray);
  const dispatch = useDispatch();
  // console.log("canvas", rightcharObj);

  const [loadedImages, setLoadedImages] = useState<
    (HTMLImageElement | undefined)[]
  >([]);
  const stageRef = React.useRef<any>();
  const canwasWidth = 300;
  const canvasHeight = 275;
  // let uri = "";
  // layerImage
  useEffect(() => {
    loadImage();
    setMintingObjectName.uri = stageRef.current.toDataURL({ pixelRatio: 6 });
    console.log("Uri In useEfect", stageRef.current.toDataURL());
  }, [selectedTraitsArray]);

  const loadImage = async () => {
    const images: any = selectedTraitsArray.map(
      (selectedTraitsArray: any) => selectedTraitsArray?.image
    );

    const loadedImages = await Promise.all(
      images.map(async (imageName: any) => {
        if (imageName != undefined) {
          const image = new window.Image();
          image.crossOrigin = "anonymous";
          image.src = imageName;
          await new Promise((resolve) => {
            image.onload = resolve;
          });
          return image;
        }
      })
    );

    setLoadedImages(loadedImages);
  };
  useEffect(() => {
    let _uri = "";
    _uri = stageRef.current.toDataURL({ pixelRatio: 6 });

    dispatch(setUri(_uri));
    console.log(stageRef.current.toDataURL(), "testin123");
  }, [loadedImages]);
  function downloadURI(uri: any, name: string) {
    // console.log(uri, "uri-------->");
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleExport = () => {
    console.log("uri", stageRef.current.toDataURL());

    const uri = stageRef.current.toDataURL({ pixelRatio: 6 });
    downloadURI(uri, "123.png");
  };

  return (
    <Box
      textAlign={"center"}
      sx={{
        width: canwasWidth,
        paddingTop: 1,
      }}
    >
      <Paper elevation={4} sx={{ background: "#D9D9D9" }}>
        <Stage width={canwasWidth} height={canvasHeight} ref={stageRef}>
          <Layer>
            {loadedImages?.map((image) => (
              <Image
                key={image?.name}
                width={canwasWidth}
                height={canvasHeight}
                image={image}
                y={0}
                x={0}
              />
            ))}
          </Layer>
        </Stage>
      </Paper>
      {/* <Button onClick={handleExport} sx={{ color: "white" }}>
        Click here to log stage data URL 
      </Button> */}
    </Box>
  );
};

export default Canvas;
