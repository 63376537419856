import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import { CardActionArea } from "@mui/material";
import { CampaignType } from "../../../types/campaignTypes";
import { Link } from "react-router-dom";
import { Base_Url } from "../../api/api";
import BackgroundImg from "../../../assets/Background/backgroundImage2.jpg";

import { axiosRequestLocal } from "../../api/api";
interface campaignPropI {
  data: CampaignType;
}

const Cards = ({ data }: campaignPropI) => {
  console.log("cards ", data);
  let backendImage = Base_Url + `/${data.background}`;
  return (
    <>
      <Link
        to={`/breeding/${data.campaign_id}`}
        state={data}
        style={{ textDecoration: "none" }}
      >
        <Card
          sx={{
            maxWidth: 345,
            minWidth: 344,
            height: 300,
            borderRadius: 6,
            position: "relative",
            overflow: "hidden",
            background: "rgba( 192, 200, 225, 0.4 );",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(2px)",
            border: "1px solid rgba(255, 255, 255, 0.18)",
            transition: " transform 0.3s ease-in-out",
            "&:hover": {
              // background: "rgba( 192, 200, 225, 0.6 )",
              // boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
              transform: "scale(1.05)",
            },
          }}
        >
          <div
            style={{
              backgroundImage: `url(${Base_Url}/background/${data.background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: -1,
              filter: "blur(10px)",
            }}
          />
          <CardActionArea>
            <CardMedia
              component="img"
              height="200px"
              image={backendImage === "" ? BackgroundImg : backendImage}
              alt="green iguana"
            />
            <CardContent
              sx={{
                height: 90,
                overflow: "auto",
                background: "rgba( 192, 200, 225, 0.4 );",
                // backdropFilter: "blur(10px)",
                // WebkitBackdropFilter: "blur(10px)",
                border: "1px solid rgba(255, 255, 255, 0.18)",
                margin: "0px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                // gutterBottom
                color="white"
              >
                {data.title.length <= 20
                  ? data.title
                  : data.title.substring(0, 20) + " ...."}
              </Typography>
              <Typography color="white" fontSize={"15px"}>
                {data.description.length <= 50
                  ? data.description
                  : data.description.substring(0, 50) + " ...."}
              </Typography>
            </CardContent>
            {/* <CardActions
              sx={{
                px: 2,
                background: "rgba( 192, 200, 225, 0.4 );",
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(10px)",
              }}
            >
              <Typography variant="body1" color="text.secondary">
                {` Launched Date : ${new Date(
                  data.launch_Date!
                ).toLocaleString()}`}
              </Typography>
            </CardActions> */}
          </CardActionArea>
        </Card>
      </Link>
    </>
  );
};

export default Cards;
