import { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ArrowLeft from "../../../../assets/BreedingAssets/ArrowLeft.png";
import ArrowRight from "../../../../assets/BreedingAssets/ArrowRight.png";
import { setMale } from "../../../../redux/GenderReducer/GenderReducer";
import MaleCarousel from "../../../Carousel/MaleCarousel";
import { validNftsI } from "../../../../types/nftTypes";
import LuxLionCard from "../../Breeding Screen Comp/LuxLionCards/LuxLionCard";

const MaleCarouselComp = () => {
  const isScreenSmallerThan1725px = useMediaQuery("(max-width:1725px)");
  const [maleCardState, setMaleCardState] = useState<any>([]);
  const campaignsSelector = useSelector((state:any) => state.campaigns.campaigns);
  
  const [maleCount, setMaleCount] = useState<number>(0);
  const [showNumber, setShowNumber] = useState<any>(0);
  const nfts: validNftsI | undefined = useSelector(
    (state: any) => state.nfts?.nftObj
  );
  const dispatch = useDispatch();
  let counting = 0;
  let counting22 = 0;
  let maleCounting = 0;
  let maleCounting22 = 0;
  useEffect(() => {
    if (nfts) setCardData(nfts, setMaleCardState);
    dispatch(setMale(nfts?.GroupA[0]?.NFTokenID));
  }, []);

  const tableMale = maleCardState?.map((element: any, index: number) => {
    return {
      ...element,
      onClick: () => {
        setingGenderFunc(element, index);
      },
    };
  });

  const setingGenderFunc = (genders: any, index: number) => {
    dispatch(setMale(genders.id));

    setMaleCount(index);
    maleCounting = index;
  };

  const settingCountLeft = () => {
    if (!nfts) return;
    const maleNftsLength = nfts?.GroupA?.length;
    if (maleCount >= nfts?.GroupA?.length - 1) {
      setMaleCount(0);

      maleCounting = 0;

      counting = 0;
    } else {
      counting = maleCount + 1;
      setMaleCount(counting);
      maleCounting = counting;
    }
    /////////////////////////////  using for count of card   //////////////////////////
    if (showNumber === 0) {
      setShowNumber(maleNftsLength - 1);
      maleCounting22 = maleNftsLength - 1;
      counting22 = maleNftsLength - 1;
    } else if (showNumber <= maleNftsLength - 1) {
      counting22 = showNumber - 1;
      setShowNumber(counting22 >= 0 ? counting22 : 0);
      maleCounting22 = counting22;
    }

    dispatch(setMale(tableMale[maleCounting]?.id));
  };

  const settingCountRight = () => {
    if (!nfts) return;
    const maleNftsLength = nfts?.GroupA?.length;

    if (maleCount === 0) {
      setMaleCount(maleNftsLength - 1);

      maleCounting = maleNftsLength - 1;
      counting = maleNftsLength - 1;
    } else if (maleCount <= maleNftsLength - 1) {
      counting = maleCount - 1;
      setMaleCount(counting);
      maleCounting = counting;
    }

    /////////////////////////////  using for count of card   //////////////////////////

    if (showNumber >= nfts?.GroupA?.length - 1) {
      setShowNumber(0);
      maleCounting22 = 0;
      counting22 = 0;
    } else {
      counting22 = showNumber + 1;
      setShowNumber(counting22);
      maleCounting22 = counting22;
    }
    dispatch(setMale(tableMale[maleCounting]?.id));
  };

  return (
    <>
      <MaleCarousel
        cards={tableMale}
        height="500px"
        width="100%"
        margin="0 auto"
        offset={20}
        showArrows={true}
        setMaleCount={setMaleCount}
        maleCount={maleCount}
      />

      {isScreenSmallerThan1725px ? (
       <Box
       sx={{
         mt: campaignsSelector?.campaignType === '2' ? -24 : -22.5,
         mb: campaignsSelector?.campaignType === '2' ? 1 : 0,
       }}
       display="flex"
       flexDirection="row"
     >
          <Grid container>
            <Grid item sm={4} style={{ zIndex: "99" }}>
              <Box onClick={() => settingCountLeft()}>
                <img
                  style={{
                    marginBottom: 0,
                    width: 18,
                    marginLeft: 5,
                  }}
                  src={ArrowLeft}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item sm={4} display={"flex"} justifyContent="center">
              <Box style={{ textAlign: "center", display: "flex" }}>
                <Typography
                  fontFamily={"Jedi"}
                  variant="h6"
                  sx={{ color: "#ffffff", zIndex: "99" }}
                >
                  {`${showNumber + 1}/${nfts?.GroupA?.length}`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item 
              sm={4}
              display={"flex"}
              justifyContent="right"
              style={{ zIndex: "99" }}
            >
              <Box onClick={() => settingCountRight()}>
                <img
                  style={{
                    paddingTop: 0,
                    width: 18,
                    marginLeft: 0,
                  }}
                  src={ArrowRight}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ mt: -10,}} display="flex" flexDirection={"row"}>
          <Grid container>
            <Grid item sm={4} style={{ zIndex: "99" }}>
              <Box onClick={() => settingCountLeft()}>
                <img
                  style={{
                    marginBottom: 0,
                    width: 18,
                    marginLeft: 5,
                  }}
                  src={ArrowLeft}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item sm={4} display={"flex"} justifyContent="center">
              <Box style={{ textAlign: "center", display: "flex" }}>
                <Typography
                  fontFamily={"Jedi"}
                  variant="h4"
                  sx={{ color: "#ffffff", zIndex: "99" }}
                >
                  {`${showNumber + 1}/${nfts?.GroupA?.length}`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
              display={"flex"}
              justifyContent="right"
              style={{ zIndex: "99" }}
            >
              <Box onClick={() => settingCountRight()}>
                <img
                  style={{
                    paddingTop: 0,
                    width: 18,
                    marginLeft: 0,
                  }}
                  src={ArrowRight}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default MaleCarouselComp;

const setCardData = (
  nfts: validNftsI,
  setMaleCardState: React.Dispatch<any>
) => {
  console.log("What is nft", nfts);
  const cards: cardObjI[] = [];

  const maleNftsArrLength = nfts?.GroupA?.length;
  console.log("length", nfts?.GroupA?.length);
  // console.log("name", nfts?.GroupA[0]?.content?.collection?.name);
  // console.log("Image", nfts?.GroupA[0]?.content?.image);
  for (let i = 0; i < maleNftsArrLength; i++) {
    let maleNftImage = nfts?.GroupA[i]?.content?.image;
    if (!maleNftImage) continue;
    let obj = {
      key: i,
      content: (
        <LuxLionCard imagen={maleNftImage} info={nfts?.GroupA[i]?.content} />
      ),
      id: nfts?.GroupA[i]?.NFTokenID,
    };
    cards.push(obj);
  }

  setMaleCardState(cards);
};

interface cardObjI {
  key: number;
  content: JSX.Element;
  id: string;
}
