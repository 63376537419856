import MobileCard from "../../../Components/MobileCard/MoblieCard";

import React, { useReducer, useRef, useEffect, useState } from "react";
import Logo from "../../../assets/BreedingAssets/logo.png";
import Chamber from "../../../assets/BreedingAssets/Chamber.png";
import BackgrounfChamber from "../../../assets/BreedingAssets/shambers.png";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import LogoutComp from "../../Logout/Logout";

import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setFemale, setMale } from "../../../redux/GenderReducer/GenderReducer";
import { Pagination, Navigation } from "swiper";
import { setSnackbar } from "../../../redux/Snackbar/SnackbarReducder";
import {
  Base_Url,
  axiosRequest,
  axiosRequestNFT,
} from "../../../Components/api/api";
import { setLoading } from "../../../redux/LoaderReducer/LoaderReducer";
import { setNFT } from "../../../redux/NFTReducer/NFTReducer";
import PotionCarouselMbl from "../../PotionCarouselMbl/PotionCarouselMbl";
import "./styles.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BreedingButton from "../../../Components/BredingComp/BreedingButton/BreedingButton";
import { CampaignType } from "../../../types/campaignTypes";
import { ToastContainer, toast } from "react-toastify";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 200,
  bgcolor: "rgba(109, 220, 254,1)",
  p: 4,
};
const token = localStorage.getItem("XummPkceJwt");

const getNftURL = "api/luxlion/nfts?userAccount=";
interface campaignPropI {
  campaignState: CampaignType;
}

const Breeding = ({ campaignState }: campaignPropI) => {
  const navigate = useNavigate();
  console.log("Campaign State in mobile-->", campaignState);

  const dispatch = useDispatch();
  const logoUrl = Base_Url + `/${campaignState?.logo}`;

  const smallScreenCheck = useMediaQuery("(min-width:450px)");
  const bigScreenCheck = useMediaQuery("(max-width:1725px)");
  const accountNum = localStorage.getItem("accnum");
  const swiperRef = useRef<any>();
  const account = useSelector((state: any) => state.xumm.account);
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [maleCardState, setMaleCardState] = useState<any>([]);
  const [maleCount, setMaleCount] = useState<number>(0);
  const [femaleCardState, setFemaleCardState] = useState<any>([]);
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const maleToken = useSelector((state: any) => state.gender?.male);
  const femaleToken = useSelector((state: any) => state.gender?.female);

  const nfts = useSelector((state: any) => state.nfts?.nftObj);
  console.log("NFTS in mobile", nfts);
  const firstHitApi2 = useSelector((state: any) => state.loader?.firstHitApi);
  const loadingState = useSelector((state: any) => state.loader?.loading);

  const [potionSelectedState, setPotionSelectedState] =
    useState<boolean>(false);

  const [isFemaleNftSelected, setIsFemaleNftSelected] =
    useState<boolean>(false);

  const [isMaleNftSelected, setIsMaleNftSelected] = useState<boolean>(false);

  const femaleCards: Array<any> = [];
  const xummObj = useSelector((state: any) => state.xumm.account);

  const cards: Array<any> = [];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleButtonClick = () => {
    if (campaignState) {
      const { campaignType, campaign_id } = campaignState;

  if (campaignType === '1') {
    if (maleToken && femaleToken && potionToken) {
      navigate(`/characterBuilding/${campaign_id}`);
    }else if(!maleToken && femaleToken && potionToken)
    {
      toast.error("Please select Group A");
    }
    else if(!femaleToken && maleToken &&  potionToken)
    {
      toast.error("Please select Group B");
    } 
    else if(femaleToken && maleToken && !potionToken)
    {
      toast.error("Please select a Potion");
    }
    else {
      toast.error("Please select all required tokens.");
    }
  } else if (campaignType === '2') {
    if (maleToken && potionToken) {
      navigate(`/characterBuilding/${campaign_id}`);
    }
    else if(!maleToken && potionToken)
    {
      toast.error("Please select Group A");
    } 
    else if(maleToken && !potionToken)
      {
        toast.error("Please select a Potion");

      }
    else {
      toast.error("Please select all required tokens.");
    }
  } else if (campaignType === '3') {
    if (potionToken) {
      navigate(`/characterBuilding/${campaign_id}`);
    } else {
      toast.error("Please select a potion.");
    }
  } else {
    toast.error("Invalid campaign type.");
  }
} else {
  toast.error("Campaign data is not available.");
}
}


  async function apitest() {
    try {
      const response = await axiosRequest("get", "xumm/isJwtValid");
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (potionToken != "") {
      setPotionSelectedState(true);
    } else {
      setPotionSelectedState(false);
    }
    console.log(
      isFemaleNftSelected,
      "isFemaleNftSelected",
      isMaleNftSelected,
      femaleToken
    );

    if (maleToken === "" || maleToken === undefined) {
      setIsMaleNftSelected(false);
    } else {
      setIsMaleNftSelected(true);
    }

    if (femaleToken === "" || femaleToken === undefined) {
      setIsFemaleNftSelected(false);
    } else {
      setIsFemaleNftSelected(true);
    }
  }, [potionToken, maleToken, femaleToken]);

  async function getNFT(data: any, id: number) {
    dispatch(setLoading(true));
    try {
      const response: any = await axiosRequestNFT(
        "post",
        `${getNftURL}${data}`,
        { id: id },
        undefined
      );
      dispatch(setNFT(response));
      dispatch(setLoading(false));
      console.log("Response of Mobile",response);
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
    }
  }
  useEffect(() => {
      getNFT(accountNum, campaignState?.campaign_id);
  }, [campaignState?.campaign_id]);

  useEffect(() => {
    document.body.style.overflow = "auto";
    setMaleCardData();
    setFemaleCardData();
  }, [nfts]);

  
  const handleOpenModal = () => {
    console.log(
      isFemaleNftSelected,
      "yeahehhhaeaeh",
      isMaleNftSelected,
      femaleToken
    );

    if (potionSelectedState === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select potion NFT `,
        })
      );
      return;
    }

    if (isMaleNftSelected === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select a Lion `,
        })
      );
      return;
    }

    if (isFemaleNftSelected === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select a Lioness `,
        })
      );
      return;
    }
    setOpenModal(true);
  };
  const setMaleCardData = () => {
    for (let i = 0; i < nfts?.GroupA?.length; i++) {
      let obj = {
        key: i,
        content: (
          <MobileCard
            imagen={nfts?.GroupA[i]?.content?.image}
            info={nfts?.GroupA[i]?.content}
          />
        ),
        id: nfts?.GroupA[i]?.NFTokenID,
      };
      cards.push(obj);
    }
    setMaleCardState(cards);
    dispatch(setMale(cards[0]?.id));
  };
  const setFemaleCardData = () => {
    for (let i = 0; i < nfts?.GroupB?.length; i++) {
      let obj = {
        key: i,
        content: (
          <MobileCard
            imagen={nfts?.GroupB[i]?.content?.image}
            info={nfts?.GroupB[i]?.content}
          />
        ),
        id: nfts?.GroupB[i]?.NFTokenID,
      };
      femaleCards.push(obj);
    }
    setFemaleCardState(femaleCards);
    dispatch(setFemale(femaleCards[0]?.id));
  };

  const setMaleId = (id: any) => {
    dispatch(setMale(id));
  };

  const setFemaleId = (id: any) => {
    dispatch(setFemale(id));
  };

  return (
    <>
      <div>
        <img src={Chamber} alt="" style={{ width: "100%", height: "100vh" }} />
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            overflow: "hidden",
          }}
        >
          {" "}
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 2,
                  justifyContent: "space-between",
                  display: "flex",
                  px: 4,
                }}
              >
                <Box >
                  <img
                    src={`${logoUrl}`}
                    alt=""
                    width="60%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/campaigns`);
                    }}
                  />
                </Box>

                <LogoutComp />
              </Box>
            </Grid>
          </Grid>
          {loadingState === false ? (
            <>
              {campaignState?.campaignType === "1" ? (
                <>
                  <Grid container style={{ zIndex: 0 }}>
                    <Grid item xs={3} />
                    {/* Collection 1 */}

                    <Grid item xs={6} display="flex" justifyContent={"center"}>
                      <Box
                        sx={{
                          mx: 0,
                          background: "rgba(63, 63, 63, 0.9)",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "4vh",
                          mt: 2,
                          textAlign: "center",
                          width: 200,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: 18,
                            fontWeight: 600,
                            textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                          }}
                        >
                          CHOOSE NFT
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid
                      item
                      sm={12}
                      sx={{ mt: 0 }}
                      xs={12}
                      justifyContent="center"
                    >
                      <Box mx={2}>
                        {nfts?.GroupA ? (
                          maleCardState ? (
                            <Swiper
                              slidesPerView={smallScreenCheck ? 2 : 1}
                              // spaceBetween={15}
                              navigation={true}
                              modules={[Pagination, Navigation]}
                              onSlideChange={(e) =>
                                setMaleId(maleCardState[e.activeIndex]?.id)
                              }
                              onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                              }}
                              style={{ paddingInline: 0 }}
                            >
                              <Box sx={{ mx: 40 }}>
                                {maleCardState.map((item: any) => (
                                  <SwiperSlide
                                    style={{
                                      paddingBlock: 0,
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box px={10}>{item.content}</Box>
                                  </SwiperSlide>
                                ))}
                              </Box>
                            </Swiper>
                          ) : (
                            <Box mt={4}>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "white",
                                  height: "50px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {"No NFT found "}
                              </Typography>
                            </Box>
                          )
                        ) : (
                          <Typography
                            variant="h5"
                            sx={{
                              color: "white",
                              height: "50px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"No NFT found "}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={3} />
                    {/* Collection 2 */}
                    <Grid item xs={12} display="flex" justifyContent="center">
                      {" "}
                      <Button
                        // onClick={handleOpenModal} //will be open when deployement
                        onClick={handleButtonClick}
                        size="small"
                        sx={{
                          opacity: 1,
                          color: "white",
                          background: "#36454F",

                          p: 1,
                          paddingInline: 2.5,
                          border: "1px solid #ffff",
                          "&:hover": {
                            background: "rgba(109, 228, 264, 0.9)",
                            color: "black",
                          },
                        }}
                      >
                        <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                          {" "}
                          Create
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={6} display="flex" justifyContent="center">
                      <Box
                        sx={{
                          mx: 0,
                          background: "rgba(63, 63, 63, 0.9)",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "4vh",
                          mt: 2,
                          textAlign: "center",
                          width: 200,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: 18,
                            fontWeight: 600,
                            textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                          }}
                        >
                          CHOOSE NFT
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item sm={12} xs={12} sx={{ mt: 0 }}>
                      <Box mx={0}>
                        {nfts?.GroupB ? (
                          <Swiper
                            slidesPerView={smallScreenCheck ? 2 : 1}
                            // spaceBetween={2}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            onSlideChange={(e) =>
                              setFemaleId(femaleCardState[e.activeIndex]?.id)
                            }
                            onBeforeInit={(swiper) => {
                              swiperRef.current = swiper;
                            }}
                            style={{ paddingInline: 0 }}
                          >
                            <Box
                              sx={{
                                ml: 80,
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {femaleCardState.map((item: any) => (
                                <SwiperSlide
                                  style={{
                                    paddingBlock: 0,
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box px={10}>{item.content}</Box>
                                </SwiperSlide>
                              ))}
                            </Box>
                          </Swiper>
                        ) : (
                          <Box mt={4}>
                            <Typography
                              variant="h5"
                              sx={{
                                color: "white",
                                height: "50px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {"No NFT found "}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {/* Potion Grid */}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        background: "rgba(217, 217, 217, 0.8);",
                        borderRadius: "15px",

                        height: 60,
                        zIndex: 99,
                        mt: 1,
                        mx: 5,
                      }}
                    >
                      <PotionCarouselMbl />
                    </Box>
                  </Grid>
                </>
              ) : campaignState?.campaignType === "2" ? (
                <>
                  <Grid container style={{ zIndex: 0 }}>
                    <Grid item xs={3} />
                    {/* Collection 1 */}

                    <Grid item xs={6} display="flex" justifyContent={"center"}>
                      <Box
                        sx={{
                          mx: 0,
                          background: "rgba(63, 63, 63, 0.9)",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "4vh",
                          mt: 2,
                          textAlign: "center",
                          width: 200,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: 18,
                            fontWeight: 600,
                            textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                          }}
                        >
                          CHOOSE NFT
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid
                      item
                      sm={12}
                      sx={{ mt: 0 }}
                      xs={12}
                      justifyContent="center"
                    >
                      <Box mx={2}>
                        {nfts?.GroupA ? (
                          maleCardState ? (
                            <Swiper
                              slidesPerView={smallScreenCheck ? 2 : 1}
                              spaceBetween={15}
                              // navigation={true}
                              // modules={[Pagination, Navigation]}
                              onSlideChange={(e) =>
                                setMaleId(maleCardState[e.activeIndex]?.id)
                              }
                              onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                              }}
                              style={{ paddingInline: 0 }}
                            >
                              <Box sx={{ mx: 40 }}>
                                {maleCardState.map((item: any) => (
                                  <SwiperSlide
                                    style={{
                                      paddingBlock: 0,
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box px={10}>{item.content}</Box>
                                  </SwiperSlide>
                                ))}
                              </Box>
                            </Swiper>
                          ) : (
                            <Box mt={4}>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "white",
                                  height: "50px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {"No NFT found "}
                              </Typography>
                            </Box>
                          )
                        ) : (
                          <Typography variant="h5"
                          sx={{
                            color: "white",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                          }}>
                            {"No NFT Found "}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={12} display="flex" justifyContent="center">
                      {" "}
                      <Button
                        // onClick={handleOpenModal}//will be open when deployement
                        onClick={handleButtonClick}
                        size="small"
                        sx={{
                          opacity: 1,
                          color: "white",
                          background: "#36454F",
                          mt:5,
                          p: 1,
                          paddingInline: 2.5,
                          border: "1px solid #ffff",
                          "&:hover": {
                            background: "rgba(109, 228, 264, 0.9)",
                            color: "black",
                          },
                        }}
                      >
                        <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                          {" "}
                          Create
                        </Typography>
                      </Button>
                    </Grid>
                    {/* Collection 2 */}
                  </Grid>
                  {/* Potion Grid */}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        background: "rgba(217, 217, 217, 0.8);",
                        borderRadius: "15px",

                        height: 60,
                        zIndex: 99,
                        mt: 1,
                        mx: 5,
                      }}
                    >
                      <PotionCarouselMbl />
                    </Box>
                  </Grid>
                </>
              ) : campaignState?.campaignType === "3" ? (
                <Grid container style={{ zIndex: 0 }}>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    {" "}
                    <Button
                      // onClick={handleOpenModal}//will be open when deployement
                      onClick={handleButtonClick}
                      size="small"
                      sx={{
                        opacity: 1,
                        color: "white",
                        background: "#36454F",
                        mt:10,
                        p: 1,
                        paddingInline: 2.5,
                        border: "1px solid #ffff",
                        "&:hover": {
                          background: "rgba(109, 228, 264, 0.9)",
                          color: "black",
                        },
                      }}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                        {" "}
                        Create
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        background: "rgba(217, 217, 217, 0.8);",
                        borderRadius: "15px",

                        height: 60,
                        zIndex: 99,
                        mt: 3,
                        mx: 5,
                      }}
                    >
                      <PotionCarouselMbl />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : (
            <Box
              // minHeight={"100px"}
              position={"absolute"}
              // maxHeight={"500px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <CircularProgress size={70} sx={{ color: "linear-gradient(to right, #000428, #004e92)" }} />
            </Box>
          )}
        </div>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to breed?
            </Typography>

            <Box mt={2}>
              <BreedingButton />

              <Button
                variant="contained"
                onClick={handleCloseModal}
                sx={{
                  opacity: 1,
                  color: "white",
                  background: "#36454F",
                  mr: 0,
                  mt: 1,
                  border: "1px solid #ffff",
                  "&:hover": {
                    background: "rgba(109, 258, 264, 0.6)",
                    color: "black",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      <ToastContainer/>
    </>
  );
};
export default Breeding;
