import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Canvas from "../../Admin-Scenes/Canvas/Canvas";
import RightGridPanel from "./GridPanels/RightGridPanels";
import LeftGridPanel from "./GridPanels/LeftGridPanels";
import Header2 from "../../../assets/BreedingAssets/heading2.png";
import LogoutComp from "../../Logout/Logout";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  API,
  API_Socket,
  axiosRequestAuth,
  axiosRequestLocal,
} from "../../../Components/api/api";
import { setSnackbar } from "../../../redux/Snackbar/SnackbarReducder";
import { connectSocket } from "../../../redux/SocketReducer/SocketReducer";
import { useParams } from "react-router-dom";
import {
  setCurentProcessNumber,
  setLoader,
  setYorProcessNumber,
} from "../../../redux/QueueProcess/QueueProcessReducer";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { setApiHit } from "../../../redux/LoaderReducer/LoaderReducer";
import { CampaignType } from "../../../types/campaignTypes";
import { Base_Url } from "../../../Components/api/api";
import { format } from "date-fns";
import enUS from "date-fns/locale/en-US"; // Import the locale

const smallCheck = {
  paddingInline: 30,
};

const bigCheck = {
  paddingInline: 100,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 800,
  bgcolor: "rgba(109, 220, 254,1)",

  boxShadow: 24,
  p: 4,
};

interface designGridPropI {
  campaignState: CampaignType;
}

const DesginGrids = ({ campaignState }: designGridPropI) => {
  console.log("Campaign State---->", campaignState.traits);
  console.log("Campaign State end date---->", campaignState.end_Date);

  const [loading, setLoading] = useState<boolean>(false);
  const [mintcheck, setMintCheck] = useState<boolean>(false);

  const bigScreenCheck = useMediaQuery("(max-width:1725px)");
  const paddingCheck = useMediaQuery("(max-width:1230px)");
  const navigate = useNavigate();
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [modalOpen2, setOpenModal2] = useState<boolean>(false);
  const femaleToken = useSelector((state: any) => state.gender.female);
  const maleToken = useSelector((state: any) => state.gender.male);
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const [checkBoxCheck1, setcheckBoxCheck1] = useState(true);
  const [checkBoxCheck2, setcheckBoxCheck2] = useState(false);
  const [traitsdata, settraitsdata] = useState([]);
  const [allLoadedImages, setAllLoadedImages] = useState<any[]>([]);
  const [layerNumberIndex, setlayerNumberIndex] = useState<number>(0);
  const dispatch = useDispatch();

  const totalTraits = traitsdata.length;
  const halfLength = Math.ceil(totalTraits / 2);
  const leftTraits = traitsdata.slice(0, halfLength);
  const rightTraits = traitsdata.slice(halfLength);
  const [isLoading, setIsLoading] = useState(true);
  let socketio = useSelector((state: any) => state.socketR.socket);
  let colction = useSelector((state: any) => state.mint.collectionName);
  let issuer = useSelector((state: any) => state.mint.issuer);
  let campid = useSelector((state: any) => state.mint.campaignId);
  let gFess = useSelector((state: any) => state.mint.gasFees);
  let collectionFamily = useSelector((state: any) => state.mint.family);
  let taxxons = useSelector((state: any) => state.mint.taxxon);
  let _uri = useSelector((state: any) => state.gender.uri);

  const [checkIfEmpty, setCheckIfEmpty] = useState<boolean>(false);

  const location = useLocation();
  console.log("what is pathname:", window.location.pathname);

  const currentPath = window.location.pathname;

  const formatDate = (dateString: Date | null): string => {
    if (!dateString) return ""; // Handle null or undefined input

    const date = new Date(dateString);
    return format(date, "dd MMM yyyy"); // 'dd' for day, 'MMM' for abbreviated month
  };
  let formattedDate = null;
  const dateStr = campaignState.end_Date;
  if (dateStr) {
    formattedDate = formatDate(dateStr);
  }
  const handleBack = () => {
    // Handle the back action
    window.history.back(); // Go back in the browser history
  };

  // get the data from breeding
  console.log(
    "Minting Object---->",
    colction,
    "2",
    issuer,
    "issuer",
    campid,
    "campid",
    gFess,
    "gFess"
  );

  const getData = async (campaignId: number | undefined) => {
    try {
      const res = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${campaignId}`
      );
      console.log("Campaign data", res.data.campaign.traits);
      settraitsdata(res.data.campaign.traits);
      //   console.log(res.data.triatcontent.data);
      //   console.log(res.data.triatcontent.triatcontent);
      //   setdata(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  // function used to merge data from left and right panel in one array with sequence
  function mergeArrays(arr1: any[], arr2: any[]) {
    const merged = [];

    let index1 = 0;
    let index2 = 0;

    while (index1 < arr1.length || index2 < arr2.length) {
      if (index1 < arr1.length && arr1[index1] !== undefined) {
        merged.push(arr1[index1]);
        index1++;
      } else if (index2 < arr2.length && arr2[index2] !== undefined) {
        merged.push(arr2[index2]);
        index2++;
      } else {
        index1++;
        index2++;
      }
    }

    return merged;
  }

  useEffect(() => {
    dispatch(setApiHit(false));
    getData(campaignState?.campaign_id);
  }, []);

  // left grid
  const [characterObjectState, setCharacterObjectState] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      leftChildTraits: {
        layerImage: [],
      },
      uri: "",
    });
  // right grid
  const [rightcharacterObjectState, setRightCharacterObjectState] =
    useState<rightBreedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      rightChildTraits: {
        layerImage: [],
      },

      uri: "",
    });

  const [characterObjectName, setCharacterObjectName] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      leftChildTraits: {
        layerImage: [],
      },
      uri: "",
    });

  const [rightCharacterObjectName, setRightCharacterObjectName] =
    useState<rightBreedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      rightChildTraits: {
        layerImage: [],
      },
      uri: "",
    });

  const [mintingObjectName, setMintingObjectName] = useState<MintingObj>({
    groupA: {
      tokenId: maleToken,
    },
    groupB: {
      tokenId: femaleToken,
    },
    potion: {
      tokenId: potionToken,
    },
    minter: {
      compainid: campid,
      taxxon: taxxons,
      collectionName: colction,
      gasFees: gFess,
      issuer: issuer,
      family: collectionFamily,
    },
    traitContents: allLoadedImages,
    campaignId: campaignState?.campaign_id,
    uri: "",
  });

  const updatedMintingObjectName = {
    ...mintingObjectName, // Copy all properties from the current state object
    traitContents: allLoadedImages, // Update the Layers property with new value
  };

  let updatedTraitContentsArr = [];
  for (let i = 0; i < mintingObjectName?.traitContents.length; i++) {
    if (mintingObjectName?.traitContents[i]?.trait_name === "transparent") {
      // Do Nothing
    } else {
      updatedTraitContentsArr.push(mintingObjectName?.traitContents[i]);
    }
  }
  mintingObjectName.traitContents = updatedTraitContentsArr;

  console.log("Minting Object ", mintingObjectName);
  const socketsReq = (socket: any) => {
    dispatch(connectSocket(socket));

    socket.on("connect", () => {
      console.log("socket Connected");
    });

    socket.on("disconnect", (reason: any) => {
      console.log("socket is disconnected", reason);
    });

    socket.on("burn/signed", (data: any) => {
      setMintCheck(true);
      dispatch(setLoader(true));
      console.log(" burn", data?.didUserSign);
      if (data?.didUserSign === false) {
        setMintCheck(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Burn request rejected from Xumm",
          })
        );
        setOpenModal(false);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Burn request accepted from Xumm",
          })
        );
        console.log("burn request accept");
      }
    });
    socket.on("mint/signed", (data: any) => {
      setOpenModal(false);
      console.log(" sign", data?.didUserSign);
      if (data?.didUserSign === false) {
        setMintCheck(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Mint request rejected from Xumm",
          })
        );
        console.log("rejected");
      } else {
        console.log("mint accept");
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Mint request accepted from Xumm",
          })
        );
        navigate("/transaction-history");
      }
    });
    socket.on("queue/progress", (data: any) => {
      setMintCheck(false);
      dispatch(setCurentProcessNumber(data.currentlyProcessing));
      console.log(" Queue Processing", data);
    });
    socket.on("queue/added", (data: any) => {
      dispatch(setYorProcessNumber(data.newMintQueueNumber));
      dispatch(setCurentProcessNumber(data.currentlyProcessing));

      console.log(" Queue Added", data);
    });
    socket.on("mint/completed", (data: any) => {
      console.log("mint/completed", data);
      setOpenModal(false);
      if (data.didFail) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              "Something went wrong, please contact support@luxlionsnft.com",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Processing",
          })
        );
      }
      dispatch(setLoader(false));
      dispatch(connectSocket({}));
      socket.disconnect();
      setMintCheck(false);
    });
  };

  const checkBoxFunc1 = () => {
    setcheckBoxCheck1(true);
    setcheckBoxCheck2(false);

    const newCharacterObjectName = structuredClone(characterObjectName);
    const newCharacterObjectState = structuredClone(characterObjectState);
    //not required
    // newCharacterObjectName.leftChildTraits.gender = "MALE";

    // newCharacterObjectState.leftChildTraits.gender = "MALE";

    setCharacterObjectName(newCharacterObjectName);

    setCharacterObjectState(newCharacterObjectState);
  };

  const checkBoxFunc2 = () => {
    setcheckBoxCheck1(false);
    setcheckBoxCheck2(true);
    const newCharacterObjectName = structuredClone(characterObjectName);
    const newCharacterObjectState = structuredClone(characterObjectState);

    // newCharacterObjectName.leftChildTraits.gender = "FEMALE";

    // newCharacterObjectState.leftChildTraits.gender = "FEMALE";

    setCharacterObjectName(newCharacterObjectName);
    setCharacterObjectState(newCharacterObjectState);
  };

  async function createNFT() {
    dispatch(setLoader(true));
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        API.cubApiURL,
        mintingObjectName,
        undefined
      );
      // handleCreate();
      // const token: any = localStorage.getItem("token");
      // socketio = io(API.socket_backend, {
      //   autoConnect: false,
      //   auth: { token },
      // });
      // socketsReq(socketio);
      console.log(response, "ola");

      if (response === false) {
        setOpenModal(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "something went wrong please logout and try again",
          })
        );
        setLoading(false);
        return;
      }
      setOpenModal(true);
      dispatch(setLoader(false));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      // toast.error( err?.response.data.message);
      console.log("data is Error", err?.response.data.message);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `${err.response.data.message}`,
        })
      );
      dispatch(setLoader(false));
    }
  }

  const handleCreate = () => {
    console.log("Clickes", checkIfEmpty);

    if (checkIfEmpty === true) {
      console.log("Clickes1");
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",

          snackbarMessage: "Please select all required Fields",
        })
      );
      // setCheckIfEmpty(false);
      return;
    }

    characterObjectName.uri = characterObjectState.uri;

    try {
      const token: any = localStorage.getItem("token");

      socketio = io(API_Socket, {
        autoConnect: false,
        auth: { token },
      });

      // useDispatch(setSocketConnected());
      socketsReq(socketio);

      socketio.connect();
    } catch (e) {
      console.log(e, "error in socket");
    }
    createNFT();
  };

  // ---------------------Use Effects----------------------
  useEffect(() => {
    console.log("IMage R------------", characterObjectState?.leftChildTraits);
    console.log(
      "IMage R------------",
      rightcharacterObjectState?.rightChildTraits.layerImage
    );
    const array = mergeArrays(
      characterObjectState?.leftChildTraits?.layerImage,
      rightcharacterObjectState?.rightChildTraits?.layerImage
    );
    console.log("indexed array", array);

    for (let i = 0; i < campaignState.traits.length; i++) {
      console.log(campaignState.traits[i]["required"], "data here");

      let campaignRequiredCount = 0;
      let arrayRequiredCount = 0;

      for (let i = 0; i < campaignState.traits.length; i++) {
        if (campaignState.traits[i]["required"] === true) {
          campaignRequiredCount++;
        }
        if (array[i] && array[i].required === true) {
          arrayRequiredCount++;
        }
      }
      console.log(campaignRequiredCount, "campaignRequiredCount");

      if (campaignRequiredCount === arrayRequiredCount) {
        setCheckIfEmpty(false);
      } else {
        setCheckIfEmpty(true);
      }
    }

    setAllLoadedImages(array);
  }, [characterObjectState, rightcharacterObjectState]);

  useEffect(() => {
    setMintingObjectName(updatedMintingObjectName);
  }, [allLoadedImages]);

  const updateUri = (newUri: any) => {
    setMintingObjectName((prevObject) => ({
      ...prevObject,
      uri: newUri,
    }));
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    updateUri(_uri);
  }, [_uri]);

  return (
    <>
      <div>
        <img
          src={Base_Url + `/${campaignState?.background}`}
          alt=""
          style={{ width: "100%", height: "100vh", position: "relative" }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              sm={3}
              sx={{ paddingTop: 0, display: "flex" }}
              justifyContent="center"
            >
              {currentPath.includes("/preview") ? (
                <Box
                  // sx={{ paddingTop: 2, cursor: "pointer" }}
                  sx={{ maxWidth: 150, paddingTop: 4 }}
                  onClick={() => window.history.back()}
                >
                  <img
                    src={`${Base_Url + `/${campaignState?.logo}`}`}
                    alt=""
                    style={{ cursor: "pointer" }}
                    width="100%"
                  />
                </Box>
              ) : (
                <Box
                  // sx={{ paddingTop: 2, cursor: "pointer" }}
                  sx={{ maxWidth: 150, paddingTop: 4 }}
                  onClick={() =>
                    navigate(`/breeding/${campaignState?.campaign_id}`)
                  }
                >
                  <img
                    src={`${Base_Url + `/${campaignState?.logo}`}`}
                    alt=""
                    style={{ cursor: "pointer" }}
                    width="100%"
                  />
                </Box>
              )}
            </Grid>

            <Grid item sm={6} sx={{ ml: 0 }} justifyContent="right">
              <Box
                width="100%"
                borderRadius="8px"
                sx={{
                  border: `1px solid ${campaignState.buttonColor}`,
                  mt: 2,
                  height: "6vh",
                  background: "rgba(70, 70, 70, 0.8);",
                }}
              >
                <Box
                  width="95%"
                  borderRadius="8px"
                  sx={{
                    paddingInline: 2,
                    // pt: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    background: "rgba(70, 70, 70, 0.8);",
                    ml: 0,
                    height: "100%",
                    color: "white",
                    zIndex: 99,
                    position: "relative",
                  }}
                >
                  {/* <img src={`${Header2}`} alt="" width="50%" /> {" :"} */}
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Breeding Session Ends in: {formattedDate}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              sm={3}
              sx={{ mt: 3, display: "flex" }}
              justifyContent="center"
            >
              <Box sx={{ textAlign: "right" }}>
                <LogoutComp />
              </Box>
            </Grid>

            <Grid item sm={12}>
              {/* /////////////////////////////////////////// Choose your traits ////////////////////////////////////////////// */}
              <Grid container sx={{ mt: 1 }}>
                <Grid item sm={4}></Grid>
                <Grid item sm={4} textAlign="center">
                  {" "}
                  <Box
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      height: "4vh",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: 18,
                        fontWeight: 600,
                        textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                      }}
                    >
                      CHOOSE YOUR TRAITS
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4}></Grid>
              </Grid>

              <Box style={paddingCheck ? smallCheck : bigCheck}>
                {isLoading ? (
                  <Box
                    minHeight={"500px"}
                    maxHeight={"500px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={70} sx={{ color: "white" }} />
                  </Box>
                ) : (
                  <Grid
                    container
                    sx={{
                      background: campaignState?.backgroundColor,
                      borderRadius: "20px",
                      minHeight: "68vh",
                      p: 2,
                      // pb: "15px",
                      px: 2,
                    }}
                  >
                    {/* /////////////////////////Left Panel////////////////////// */}
                    {leftTraits.length > 0 ? (
                      <>
                        <Grid item sm={4} p={0}>
                          <LeftGridPanel
                            traitsObject={leftTraits}
                            canvasObject={characterObjectState}
                            setCanvasObject={setCharacterObjectState}
                            nameObject={characterObjectName}
                            setNameObject={setCharacterObjectName}
                          />
                        </Grid>

                        {/* /////////////////////////   Canvas   ////////////////////// */}
                        <Grid
                          item
                          sm={4}
                          sx={{ display: "flex" }}
                          justifyContent="center"
                        >
                          <Box>
                            <Canvas
                              selectedTraitsArray={allLoadedImages}
                              setMintingObjectName={mintingObjectName}
                            />

                            {currentPath.includes("/preview") ? (
                              <Box textAlign="center" sx={{ mt: 2 }}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "white",
                                    background: "rgba(70, 70, 70, 0.8)",
                                    border: "1px solid #ffff",
                                    "&:hover": {
                                      background: "#36454F",
                                    },
                                  }}
                                  onClick={handleBack}
                                >
                                  Go Back
                                </Button>
                              </Box>
                            ) : (
                              <Box textAlign="center" sx={{ mt: 2 }}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "white",
                                    background: "rgba(70, 70, 70, 0.8)",
                                    border: "1px solid #ffff",
                                    "&:hover": {
                                      background: "#36454F",
                                    },
                                  }}
                                  onClick={handleCreate}
                                >
                                  {loading === false ? (
                                    "Create"
                                  ) : (
                                    <CircularProgress size={20} />
                                  )}
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                        {/* /////////////////////////  Right Panel   ////////////////////// */}
                        <Grid item sm={4}>
                          {rightTraits.length > 0 ? (
                            <RightGridPanel
                              traitsObject={rightTraits}
                              canvasObject={rightcharacterObjectState}
                              setCanvasObject={setRightCharacterObjectState}
                              nameObject={rightCharacterObjectName}
                              setNameObject={setRightCharacterObjectName}
                            />
                          ) : (
                            <Typography variant="h6">
                              More than 1 traits are not available in this
                              campaign
                            </Typography>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50vh",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h3">
                          No Traits are Available in this Campaigns
                        </Typography>
                      </div>
                    )}
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* ///////////////////////////////  Bottom grid  /////////////////////////////////////// */}
        </div>
      </div>
      <>
        <Modal open={modalOpen}>
          <Box sx={style}>
            {mintcheck === true ? (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ fontFamily: "Jedi", fontWeight: 600 }}
                >
                  {
                    "Mint request generated. Awaiting xumm approval! (do not refresh page)"
                  }
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ fontFamily: "Jedi", fontWeight: 600 }}
                >
                  {
                    "Burn request generated. Awaiting xumm approval!  (do not refresh page)"
                  }
                </Typography>
              </>
            )}
          </Box>
        </Modal>
      </>
    </>
  );
};
export default DesginGrids;

export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    skin: string;
    eyewear: string;
    mouth: string;
    clothing: string;
    eyes: string;
    headgear: string;
    accesories: string;
  };
}

export interface breedRequestBody {
  male: {
    tokenId: string;
  };
  female: {
    tokenId: string;
  };
  potion: {
    tokenId: string;
  };
  leftChildTraits: LeftLayerOfImages;
  uri: string;
}
export interface rightBreedRequestBody {
  male: {
    tokenId: string;
  };
  female: {
    tokenId: string;
  };
  potion: {
    tokenId: string;
  };
  rightChildTraits: RightLayerOfImages;
  uri: string;
}
export interface MintingObj {
  groupA: {
    tokenId: string;
  };
  groupB: {
    tokenId: string;
  };
  potion: {
    tokenId: string;
  };
  minter: minterI;
  traitContents: Array<any>;
  campaignId: number | undefined;
  uri: string;
}
export interface APIObject {
  groupA: {
    tokenId: string;
  };
  groupB: {
    tokenId: string;
  };
  potion: {
    tokenId: string;
  };
  minter: minterI;
  Layers: Array<string>;
  uri: string;
}

export interface LeftLayerOfImages {
  layerImage: any[];
}
export interface RightLayerOfImages {
  layerImage: any[];
}
export interface minterI {
  compainid: number;
  taxxon: number;
  collectionName: string;
  gasFees: number;
  issuer: string;
  family: string;
}

export interface traitsI {
  gender: Gender;
  layerNumber: string;
  layerNumber2: string;
  background: string;
  skin: string;
  eyewear: string;
  mouth: string;
  clothing: string;
  eyes: string;
  headgear: string;
  accesories: string;
}

type Gender = "MALE" | "FEMALE";
